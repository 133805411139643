import React from 'react';
import L from "leaflet";

import Button from 'components/common/Button';
import Pill from 'components/common/Pill';

import MatchComparisonBar from '../bar/MatchComparisonBar';
import { HeadText, Text, LINK } from 'components/common/Typography';
import { StarIcon, LocationIcon } from 'components/common/Icon'

import './HotelOption.scss';

const MAP_LOCATION_COLOR = '#EF6820';

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3
});

type HotelResult = {
    name: string;
    type: 'hotel' | 'vacation rental';
    check_in_time: string;
    check_out_time: string;
    price?: number;
    description?: string;
    hotel_class?: string;
    amenities?: string[];
    website?: string;
    phone?: string;
    rating?: number;
    room_details?: any;
    address?: string;
    booking_url: string;
    ranking_reasoning?: string;
    gps_coordinates?: {
        lat: number;
        lng: number;
    }
}

type HotelOptionProps = {
    option: HotelResult;
    index: number;
    score?: number;
    allScores?: number[];
};

interface HotelOptionState {
    map: L.Map | null;
}

class HotelOption extends React.Component<HotelOptionProps, HotelOptionState> {
    private mapRef: React.RefObject<HTMLDivElement>;

    constructor(props: HotelOptionProps) {
        super(props);

        this.state = {
            map: null
        };

        this.mapRef = React.createRef();
    }

    componentDidMount(): void {
        this.drawMap();
    }

    drawMap = () => {
        const { option } = this.props;
        const lat = option.gps_coordinates?.lat;
        const lng = option.gps_coordinates?.lng;

        if (!this.state.map && lat && lng && this.mapRef.current) {
            const zoomScale = 16;

            const initializedMap = L.map(this.mapRef.current, {
                zoomControl: false,
                attributionControl: false,
            }).setView([lat, lng], zoomScale);

            const tileLayer = L.tileLayer(
                "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            ).addTo(initializedMap);

            tileLayer.getContainer().style.filter = "grayscale(100%)";

            // @ts-ignore
            L.svg({ clickable: true }).addTo(initializedMap);

            L.circleMarker([lat, lng], {
                color: MAP_LOCATION_COLOR,
                fillColor: MAP_LOCATION_COLOR,
                fillOpacity: 0.5,
                radius: 4
            }).addTo(initializedMap);

            this.setState({ map: initializedMap });
        }
    };

    renderButton = () => {
        const { option } = this.props;

        return (
            <div className='flight-option-button-wrapper'>

                <div className='flight-option-price-display'>
                    <HeadText fontSize='s'>{USDollar.format(option.price || 0)}</HeadText>
                    <Text fontSize='xs' fontWeight='light'>Total rate</Text>
                </div>

                <Button
                    size="2xl"
                    disabled={!option.booking_url}
                    onClick={() => window.open(option.booking_url, '_blank')}
                    style={{ width: '100%' }}
                >Book Now</Button>
            </div>
        );
    }

    getRankingReason = () => {
        const { option } = this.props;

        return option.ranking_reasoning ? (
            <div className="option-ranking-reason">
                <Text fontWeight='bold'>Details</Text>
                <Text fontSize='s'>{option.ranking_reasoning}</Text>
            </div>
        ) : <></>;
    }

    renderOptionDetails = () => {
        const { option } = this.props;
        const lat = option.gps_coordinates?.lat;
        const lng = option.gps_coordinates?.lng;

        return (
            <div className="option-details">
                {lat && lng && (
                    <div className="option-details-body-wrapper">
                        <div ref={this.mapRef} style={{ height: '300px', flex: 1 }}></div>
                    </div>
                )}

                <div className='flex flex-row self-stretch'>
                    <div className='flex-1 text-left'>
                        <Text fontSize='xs'>Check-in</Text>
                    </div>
                    <div className='flex-1 text-right'>
                        <Text fontSize='xs'>Check-out</Text>
                    </div>
                </div>
                <div className='flex flex-row self-stretch'>
                    <div className='flex-1 text-left'>
                        <Text>{option.check_in_time}</Text>
                    </div>
                    <div className='flex-1 text-right'>
                        <Text>{option.check_out_time}</Text>
                    </div>
                </div>

                <Text>{option.description}</Text>
            </div>
        );
    }

    render() {
        const { option, index } = this.props;
        const isTopPick = index === 0;

        return (
            <div className="adapter-intention-option">
                <div className="option-price-header">
                    <div className="option-price-header-wrapper">
                        <HeadText fontSize='xs'>{option.name}</HeadText>
                        <Text fontSize='s'><StarIcon />&nbsp;{option.rating} star hotel</Text>

                        {option.address && (
                            <Text fontSize='s'>
                                <LINK href={`https://google.com/maps/place/${encodeURIComponent(option.address)}`} target='_blank'>
                                    <LocationIcon />&nbsp;{option.address}
                                </LINK>
                            </Text>
                        )}
                    </div>
                    {isTopPick && <div className='absolute top-0 right-0'><Pill highlighted>Best Match</Pill></div>}
                </div>

                {this.renderOptionDetails()}

                {this.renderButton()}

                <MatchComparisonBar
                    score={this.props.score}
                    allScores={this.props.allScores}
                />

                {this.getRankingReason()}
            </div>
        );
    }
}

export default HotelOption;