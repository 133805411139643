import React from 'react';

import './Intention.scss';
import Popover from '../Popover';
import { Text, LI, UL } from '../Typography';
import { InfoIcon } from '../Icon';

type SuggestionCategory = "flight" | "dining" | "hotel";

export type PromptSuggestion = {
    category: SuggestionCategory;
    request: string;
    short_request: string;
    refs: string[];
}

const IntentionInfoIcon = ({ refs }) => {
    const icon = (<InfoIcon cls="info relative" />);

    if (!refs?.length) {
        return icon;
    }

    return (
        <Popover
            trigger={icon}
            openDelay={600}
        >
            <Text fontSize='s'>How did we infer this?</Text>
            <UL>
                {refs.map((ref, index) => (
                    <LI key={index}><Text fontSize='s'>{ref}</Text></LI>
                ))}
            </UL>
        </Popover>
    );
}

type IntentionProps = {
    description: string;
    Icon: React.ComponentType<{ color: string }>;
    prompt: string;
    refs?: string[];
    store?: {
        setRawPrompt: (prompt: string) => void;
    };
}

const Intention: React.FC<IntentionProps> = ({
    description = '',
    prompt = '',
    Icon,
    refs = [],
    store
}) => {
    return (
        <div
            className="adapter-intention"
            onClick={() => store?.setRawPrompt(prompt)}
            // @ts-ignore
            tabIndex="0"
        >
            <Icon color="#73788C" />
            <div className="adapter-intention-content">
                <Text fontSize='s'>{description}</Text>
            </div>

            <IntentionInfoIcon refs={refs} />
        </div>
    );
};

export default Intention;

export const IntentionLoading = () => (
    <div className="adapter-intention">
        <div className='icon-placeholder animated-background'></div>
        <div className="adapter-intention-content">
            <p className='animated-background'>&nbsp;</p>

            <p className='animated-background'>&nbsp;</p>
        </div>
    </div>
);