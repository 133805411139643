import React, { useState, useRef } from 'react';
import { 
    useFloating, 
    useHover, 
    useInteractions,
    useDismiss,
    useClick,
    FloatingArrow, 
    arrow,
    offset
} from '@floating-ui/react';
import {autoPlacement} from '@floating-ui/dom';

import './Popover.scss';

type PopoverProps = {
    children: React.ReactNode;
    trigger: React.ReactNode;
    openDelay?: number;
};

const Popover = (props) => {
    const { children, trigger, openDelay }: PopoverProps = props;

    const arrowRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    
    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            arrow({ element: arrowRef }),
            offset({ mainAxis: 50 }),
            autoPlacement()
        ],
    });

    const dismiss = useDismiss(context, {
        referencePress: true,
    });

    const click = useClick(context, {
        ignoreMouse: true,
        stickIfOpen: true,
    });

    const hover = useHover(context, {
        delay: openDelay ? {
            open: openDelay,
            close: 0
        } : undefined
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([ hover, dismiss, click ]);

    return (
        <>
            <span
                ref={refs.setReference} 
                {...getReferenceProps()}
                className="adapter-popover-trigger"
            >
                {trigger}
            </span>

            {isOpen && (
                <div
                    className="adapter-popover-content" 
                    ref={refs.setFloating} 
                    style={floatingStyles}
                    {...getFloatingProps()}
                >
                    <FloatingArrow ref={arrowRef} context={context} fill="#2E3038" />
                    {children}
                </div>
            )}
        </>
    );
}

export default Popover;