// /frontend/src/components/common/Loading/DossierLoader.tsx

import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const colors = {
  primary: '#8B5CF6',
  secondary: '#C4B5FD',
  accent: '#F0EAFF',
  highlight: '#DDD6FE',
  sparkle: '#FAFAFA'
};

const shimmer = keyframes`
  0% { background-position: -1000px 0; opacity: 0.5; }
  50% { opacity: 0.8; }
  100% { background-position: 1000px 0; opacity: 0.5; }
`;

const rotate = keyframes`
  from { transform: rotate(0deg) scale(1); }
  50% { transform: rotate(180deg) scale(1.05); }
  to { transform: rotate(360deg) scale(1); }
`;

const ripple = keyframes`
  0% { transform: translate(-50%, -50%) scale(1); opacity: 0.6; }
  50% { opacity: 0.3; }
  100% { transform: translate(-50%, -50%) scale(1.4); opacity: 0; }
`;

const float = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  25% { transform: translate(-50%, -53%) rotate(1deg); }
  75% { transform: translate(-50%, -47%) rotate(-1deg); }
  100% { transform: translate(-50%, -50%) rotate(0deg); }
`;

const particleFloat = keyframes`
  0% { transform: translate(0, 0) scale(1); opacity: 0.8; }
  25% { transform: translate(5px, -5px) scale(1.1); opacity: 0.9; }
  50% { transform: translate(10px, 0) scale(1); opacity: 0.8; }
  75% { transform: translate(5px, 5px) scale(1.1); opacity: 0.9; }
  100% { transform: translate(0, 0) scale(1); opacity: 0.8; }
`;

const sparkle = keyframes`
  0%, 100% { opacity: 0; transform: scale(0.5) rotate(0deg); }
  50% { opacity: 1; transform: scale(1.2) rotate(180deg); }
`;

const glow = keyframes`
  0% { opacity: 0.5; filter: blur(2px) brightness(1); }
  50% { opacity: 1; filter: blur(1px) brightness(1.5); }
  100% { opacity: 0.5; filter: blur(2px) brightness(1); }
`;

const mirrorShine = keyframes`
  0% {
    opacity: 0.3;
    transform: translate(-50%, -50%) scale(0.8);
  }
  50% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    opacity: 0.3;
    transform: translate(-50%, -50%) scale(0.8);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;
  border-radius: 20px;
`;

const MirrorFrame = styled.div`
  position: relative;
  width: 340px;
  height: 440px;
  border-radius: 170px 170px 85px 85px;
  background: linear-gradient(
    145deg,
    ${colors.accent} 0%,
    ${colors.highlight} 50%,
    ${colors.accent} 100%
  );
  box-shadow: 
    0 0 40px rgba(139, 92, 246, 0.2),
    inset 0 0 30px ${colors.secondary}20,
    0 10px 20px ${colors.secondary}20;
  overflow: hidden;
  backdrop-filter: blur(8px);
  border: 1px solid ${colors.highlight};
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      transparent,
      ${colors.highlight},
      transparent
    );
    background-size: 200% 100%;
    animation: ${shimmer} 3s infinite linear;
    opacity: 0.5;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      ${colors.accent}40 0%,
      ${colors.highlight}20 50%,
      ${colors.accent}40 100%
    );
    pointer-events: none;
  }
`;

const InsightRing = styled(motion.div)<{ index: number }>`
  position: absolute;
  border: 2px solid rgba(139, 92, 246, ${props => 0.4 - props.index * 0.1});
  border-radius: 50%;
  animation: ${rotate} ${props => 20 - props.index * 5}s linear infinite;
  
  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: ${colors.primary};
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(139, 92, 246, 0.8);
    animation: ${glow} 2s ease-in-out infinite;
  }
`;

const ParticleEffect = styled.div<{ size: number; speed: number }>`
  position: absolute;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background: radial-gradient(circle at 50% 50%, 
    rgba(139, 92, 246, 0.8),
    rgba(196, 181, 253, 0.2) 70%);
  border-radius: 50%;
  filter: blur(1px);
  animation: ${particleFloat} ${props => props.speed}s ease-in-out infinite;
  opacity: 0.8;
`;

const SparkleEffect = styled.div<{ duration: number; delay: number }>`
  position: absolute;
  width: 3px;
  height: 3px;
  background: white;
  border-radius: 50%;
  animation: ${sparkle} ${props => props.duration}s ease-in-out infinite;
  animation-delay: ${props => props.delay}s;
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
`;

const ProfileSilhouette = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 55px;
  height: 76px;
  background: linear-gradient(
    135deg,
    rgba(139, 92, 246, 0.4),
    rgba(196, 181, 253, 0.4)
  );
  border-radius: 27px 27px 34px 34px;
  animation: ${float} 6s ease-in-out infinite;
  backdrop-filter: blur(5px);
  box-shadow: 
    0 0 30px rgba(139, 92, 246, 0.3),
    inset 0 0 20px rgba(255, 255, 255, 0.5);

  &::before {
    content: '';
    position: absolute;
    top: -17px;
    left: 50%;
    transform: translateX(-50%);
    width: 29px;
    height: 29px;
    background: linear-gradient(
      135deg,
      rgba(139, 92, 246, 0.4),
      rgba(196, 181, 253, 0.4)
    );
    border-radius: 50%;
    box-shadow: 
      0 0 20px rgba(139, 92, 246, 0.3),
      inset 0 0 15px rgba(255, 255, 255, 0.5);
  }
`;

const InsightText = styled(motion.div)`
  font-family: "SF Pro Display", -apple-system, BlinkMacSystemFont, sans-serif;
  background: linear-gradient(45deg, ${colors.primary}, ${colors.secondary});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 2rem;
  max-width: 300px;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: -0.02em;
`;


const RippleEffect = styled.div<{ top: string; left: string; size: number; delay: number }>`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border: 1.5px solid ${colors.secondary}80;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: ${ripple} 4s ease-out infinite;
  animation-delay: ${props => props.delay}s;
  pointer-events: none;
  mix-blend-mode: screen;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 1.5px solid ${colors.accent}60;
    border-radius: 50%;
    animation: ${ripple} 4s ease-out infinite;
    animation-delay: ${props => props.delay + 0.5}s;
  }

  &::after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 1.5px solid ${colors.highlight}40;
    border-radius: 50%;
    animation: ${ripple} 4s ease-out infinite;
    animation-delay: ${props => props.delay + 1}s;
  }
`;

const GlassOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.2) 30%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0.2) 70%,
    rgba(255, 255, 255, 0.6) 100%
  );
  border-radius: inherit;
  opacity: 0.7;
  
  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: radial-gradient(
      circle at center,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.1) 50%,
      transparent 70%
    );
    animation: ${shimmer} 6s infinite linear;
    transform: rotate(-45deg);
  }
`;

const MirrorShine = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.1) 30%,
    transparent 70%
  );
  transform: translate(-50%, -50%);
  animation: ${mirrorShine} 8s infinite ease-in-out;
  pointer-events: none;
`;

const MirrorReflection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.1) 100%
  );
  pointer-events: none;
  border-radius: inherit;
`;

const MirrorEdge = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  box-shadow: 
    inset 0 0 20px rgba(255, 255, 255, 0.5),
    inset 0 0 40px rgba(255, 255, 255, 0.2);
  pointer-events: none;
`;

const seedOfLifePositions = [
  { angle: 0, radius: 60 },    // Right
  { angle: 60, radius: 60 },   // Top right
  { angle: 120, radius: 60 },  // Top left
  { angle: 180, radius: 60 },  // Left
  { angle: 240, radius: 60 },  // Bottom left
  { angle: 300, radius: 60 }   // Bottom right
];

const createSeedOfLifeRipples = () => {
  const centerX = 50;
  const centerY = 50;
  
  return seedOfLifePositions.map((pos, index) => {
    const angleRad = (pos.angle * Math.PI) / 180;
    const x = centerX + (pos.radius * Math.cos(angleRad));
    const y = centerY + (pos.radius * Math.sin(angleRad));
    
    return {
      id: index,
      top: `${y}%`,
      left: `${x}%`,
      delay: index * 0.2,
      size: 120
    };
  });
};

const createParticles = (count: number) => {
  return Array.from({ length: count }, (_, i) => ({
    id: i,
    top: `${Math.random() * 100}%`,
    left: `${Math.random() * 100}%`,
    size: Math.random() * 4 + 2,
    speed: Math.random() * 3 + 4,
    delay: Math.random() * 2,
    opacity: Math.random() * 0.3 + 0.5
  }));
};

const createSparkles = (count: number) => {
  return Array.from({ length: count }, (_, i) => ({
    id: i,
    top: `${Math.random() * 100}%`,
    left: `${Math.random() * 100}%`,
    duration: Math.random() * 2 + 1,
    delay: Math.random() * 2
  }));
};

const messages = [
  "✨ Reflecting on your data..."
];

const rings = [
  { size: 240, duration: 20, delay: 0 },
  { size: 190, duration: 15, delay: 0.2 },
  { size: 140, duration: 10, delay: 0.4 }
];

export const DossierLoader: React.FC = () => {
  const [message, setMessage] = useState(messages[0]);
  const particles = createParticles(12);
  const sparkles = createSparkles(15);
  const ripples = createSeedOfLifeRipples();

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % messages.length;
      setMessage(messages[currentIndex]);
    }, 3500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <MirrorFrame>
        <GlassOverlay />
        <MirrorShine />
        {rings.map((ring, index) => (
          <InsightRing
            key={index}
            index={index}
            style={{
              width: ring.size,
              height: ring.size,
              left: `calc(50% - ${ring.size / 2}px)`,
              top: `calc(50% - ${ring.size / 2}px)`,
              opacity: 0.7
            }}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 0.7, scale: 1 }}
            transition={{
              duration: 1.5,
              delay: ring.delay,
              ease: "easeOut"
            }}
          />
        ))}
        {particles.map(particle => (
          <ParticleEffect
            key={particle.id}
            size={particle.size}
            speed={particle.speed}
            style={{
              top: particle.top,
              left: particle.left,
              animationDelay: `${particle.delay}s`,
              opacity: particle.opacity,
              filter: 'blur(0.5px)'
            }}
          />
        ))}
        {sparkles.map(sparkle => (
          <SparkleEffect
            key={sparkle.id}
            duration={sparkle.duration}
            delay={sparkle.delay}
            style={{
              top: sparkle.top,
              left: sparkle.left,
              backdropFilter: 'blur(2px)',
              WebkitBackdropFilter: 'blur(2px)'
            }}
          />
        ))}
        <ProfileSilhouette
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.8 }}
          transition={{ 
            duration: 1.5,
            ease: "easeInOut"
          }}
        />
        {ripples.map(ripple => (
          <RippleEffect
            key={ripple.id}
            top={ripple.top}
            left={ripple.left}
            size={ripple.size}
            delay={ripple.delay}
          />
        ))}
        <MirrorReflection />
        <MirrorEdge />
      </MirrorFrame>
      <AnimatePresence mode="wait">
        <InsightText
          key={message}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.7 }}
        >
          {message}
        </InsightText>
      </AnimatePresence>
    </Container>
  );
};

export default DossierLoader;

