// /frontend/src/pages/Atlas/PreferenceDetail.tsx

import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { addSpacesToTitleCase } from 'util/Text';
import MemoryAnalysis from 'components/MemoryAnalysis';

// Types
interface Preference {
    id: string;
    categoryId: string;
    categoryType: string;
    tileType: string;
    name: string;
    value: any;
    metadata?: {
        confidence?: number;
        lastUpdated?: string;
        source?: string;
        created_at?: string;
        last_referenced?: string;
        updated_at?: string;
        categories?: string[];
        location?: string;
        [key: string]: any;
    };
    location?: {
        code?: string;
        city?: string;
        state?: string;
        country?: string;
    };
}

interface HistoryItem {
    provider: string;
    date: string;
    confidence?: number;
    lastReferenced?: string;
    updated?: string;
}

interface UnderstandingItem {
    title: string;
    value: any;
    type: "text" | "percentage" | "memory";
    date?: string;
}

interface PreferenceDetailProps {
    preference: Preference;
    history: HistoryItem[];
    understanding: UnderstandingItem[];
    onBack: () => void;
}

// Base Styled Components
const Container = styled(motion.div)`
    background: white;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    margin: 24px;
    overflow: hidden;
    width: calc(100% - 48px);
    max-width: 1200px;
`;

const Header = styled.div`
    padding: 24px;
    background: #F4F3FF;
    border-bottom: 1px solid #E6E3FF;
`;

const CategoryLabel = styled.div`
    color: #727271;
    font-family: "Rand Light", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 4px;
`;

const PreferenceName = styled.h1`
    font-size: 28px;
    font-family: "Rand Medium", sans-serif;
    color: #171717;
    margin: 8px 0;
`;

const PreferenceValue = styled.div`
    font-size: 20px;
    color: #333;
    font-family: "Rand Medium", sans-serif;
    margin-top: 8px;
`;

const Section = styled.div`
    padding: 24px;
    border-top: 1px solid #EAEAEA;
`;

const SectionTitle = styled.h2`
    font-size: 18px;
    font-family: "Rand Medium", sans-serif;
    color: #171717;
    margin-bottom: 16px;
`;
// Part 2: Memory-specific components and other styled components

const MemoryContent = styled.div`
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    font-family: "Rand Light", sans-serif;
    padding: 24px;
    white-space: pre-wrap;
`;

const CategoryTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 16px;
`;

const CategoryTag = styled.span`
    display: inline-block;
    padding: 4px 12px;
    background: #F4F3FF;
    border-radius: 12px;
    font-size: 12px;
    color: #666;
    font-family: "Rand Medium", sans-serif;
`;

const TimelineSection = styled(Section)`
    background: #F9F9F9;
`;

const TimelineItem = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding: 16px 0;
    
    &:not(:last-child) {
        border-bottom: 1px solid #EAEAEA;
    }
`;

const TimelineIcon = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #E6E3FF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

const TimelineContent = styled.div`
    flex: 1;
`;

const TimelineTitle = styled.div`
    font-size: 14px;
    color: #333;
    font-family: "Rand Medium", sans-serif;
    margin-bottom: 4px;
`;

const DateText = styled.div`
    font-size: 12px;
    color: #999;
    font-family: "Rand Light", sans-serif;
`;

// Category-specific components
const FlightGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
    padding: 24px;
`;

const RouteCard = styled.div`
    background: white;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`;

const RouteIcon = styled.div`
    font-size: 24px;
`;

const DiningSection = styled(Section)`
    background: #FFF8F5;
`;

const CuisineTag = styled(CategoryTag)`
    background: #FFF0E8;
    color: #D4691E;
`;

const LodgingSection = styled(Section)`
    background: #F5F9FF;
`;

const PropertyCard = styled.div`
    background: white;
    border-radius: 12px;
    padding: 16px;
    border-left: 4px solid #4A90E2;
`;

const PurchaseSection = styled(Section)`
    background: #F5FFF7;
`;

const MetricCard = styled.div`
    background: white;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const MetricLabel = styled.div`
    font-size: 12px;
    color: #666;
    font-family: "Rand Light", sans-serif;
`;

const MetricValue = styled.div`
    font-size: 24px;
    color: #171717;
    font-family: "Rand Medium", sans-serif;
`;

const MetricGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
    margin-top: 16px;
`;

const ConfidenceIndicator = styled.div<{ score: number }>`
    display: inline-block;
    padding: 4px 8px;
    background: ${props => {
        if (props.score >= 0.8) return '#E6FFE6';
        if (props.score >= 0.6) return '#FFF3E6';
        return '#FFE6E6';
    }};
    color: ${props => {
        if (props.score >= 0.8) return '#28A745';
        if (props.score >= 0.6) return '#FD7E14';
        return '#DC3545';
    }};
    border-radius: 4px;
    font-size: 12px;
    font-family: "Rand Medium", sans-serif;
`;

const MetadataGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
    margin-top: 16px;
`;

const MetadataItem = styled.div`
    background: white;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
`;

const MetadataLabel = styled.div`
    font-size: 12px;
    color: #666;
    margin-bottom: 4px;
    font-family: "Rand Light", sans-serif;
`;

const MetadataValue = styled.div`
    font-size: 14px;
    color: #333;
    font-family: "Rand Medium", sans-serif;
    word-break: break-word;
    white-space: pre-wrap;
`;
// Part 3: Component Implementation

export const PreferenceDetail: React.FC<PreferenceDetailProps> = ({
    preference,
    history,
    understanding,
    onBack
}) => {
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [analysisError, setAnalysisError] = useState<string | null>(null);

    const formatDate = (dateString: string) => {
        try {
            return new Date(dateString).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        } catch (e) {
            return dateString;
        }
    };

    const renderMemoryView = () => (
        <>
            <Header>
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <CategoryLabel>Memory</CategoryLabel>
                    <PreferenceName>
                        {preference.value.substring(0, 50)}
                        {preference.value.length > 50 ? '...' : ''}
                    </PreferenceName>
                    {preference.metadata?.categories && (
                        <CategoryTags>
                            {preference.metadata.categories.map((category: string, index: number) => (
                                <CategoryTag key={index}>
                                    {category.replace('Category.', '')}
                                </CategoryTag>
                            ))}
                        </CategoryTags>
                    )}
                </motion.div>
            </Header>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.1 }}
            >
                <MemoryContent>
                    {preference.value}
                </MemoryContent>
            </motion.div>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.2 }}
            >
                <Section>
                    <MemoryAnalysis 
                        memoryContent={preference.value}
                        categories={preference.metadata?.categories}
                        timestamp={preference.metadata?.created_at}
                        location={preference.metadata?.location}
                    />
                </Section>
            </motion.div>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.3 }}
            >
                <TimelineSection>
                    <SectionTitle>Timeline</SectionTitle>
                    
                    {/* Created */}
                    <TimelineItem>
                        <TimelineIcon>📝</TimelineIcon>
                        <TimelineContent>
                            <TimelineTitle>Created</TimelineTitle>
                            <DateText>
                                {formatDate(preference.metadata?.created_at || '')}
                            </DateText>
                        </TimelineContent>
                    </TimelineItem>
                    
                    {/* Last Referenced */}
                    {preference.metadata?.last_referenced && (
                        <TimelineItem>
                            <TimelineIcon>👁️</TimelineIcon>
                            <TimelineContent>
                                <TimelineTitle>Last Referenced</TimelineTitle>
                                <DateText>
                                    {formatDate(preference.metadata.last_referenced)}
                                </DateText>
                            </TimelineContent>
                        </TimelineItem>
                    )}
                    
                    {/* Last Updated */}
                    {preference.metadata?.updated_at && (
                        <TimelineItem>
                            <TimelineIcon>🔄</TimelineIcon>
                            <TimelineContent>
                                <TimelineTitle>Last Updated</TimelineTitle>
                                <DateText>
                                    {formatDate(preference.metadata.updated_at)}
                                </DateText>
                            </TimelineContent>
                        </TimelineItem>
                    )}

                    {/* Source Information */}
                    {preference.metadata?.source && (
                        <TimelineItem>
                            <TimelineIcon>📱</TimelineIcon>
                            <TimelineContent>
                                <TimelineTitle>Source</TimelineTitle>
                                <DateText>
                                    {preference.metadata.source}
                                </DateText>
                            </TimelineContent>
                        </TimelineItem>
                    )}

                    {/* Confidence Score */}
                    {preference.metadata?.confidence && (
                        <TimelineItem>
                            <TimelineIcon>📊</TimelineIcon>
                            <TimelineContent>
                                <TimelineTitle>Confidence Score</TimelineTitle>
                                <ConfidenceIndicator score={preference.metadata.confidence}>
                                    {(preference.metadata.confidence * 100).toFixed(1)}%
                                </ConfidenceIndicator>
                            </TimelineContent>
                        </TimelineItem>
                    )}
                </TimelineSection>
            </motion.div>

            {/* Additional Metadata Section */}
            {preference.metadata && Object.keys(preference.metadata).some(key => 
                !['categories', 'created_at', 'updated_at', 'last_referenced', 'source', 'confidence'].includes(key)
            ) && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, delay: 0.4 }}
                >
                    <Section>
                        <SectionTitle>Additional Details</SectionTitle>
                        <MetadataGrid>
                            {Object.entries(preference.metadata)
                                .filter(([key]) => !['categories', 'created_at', 'updated_at', 'last_referenced', 'source', 'confidence'].includes(key))
                                .map(([key, value]) => (
                                    <MetadataItem key={key}>
                                        <MetadataLabel>{addSpacesToTitleCase(key)}</MetadataLabel>
                                        <MetadataValue>
                                            {typeof value === 'object' ? JSON.stringify(value, null, 2) : String(value)}
                                        </MetadataValue>
                                    </MetadataItem>
                                ))}
                        </MetadataGrid>
                    </Section>
                </motion.div>
            )}
        </>
    );
// Part 4: Remaining render functions and final return statement

    const renderFlightView = () => (
        <>
            <Header>
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <CategoryLabel>Flight Preference</CategoryLabel>
                    <PreferenceName>{addSpacesToTitleCase(preference.name)}</PreferenceName>
                    <PreferenceValue>{preference.value}</PreferenceValue>
                </motion.div>
            </Header>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.1 }}
            >
                <FlightGrid>
                    {preference.name.toLowerCase().includes('route') ? (
                        <RouteCard>
                            <RouteIcon>✈️</RouteIcon>
                            <div>
                                <MetricLabel>Route Details</MetricLabel>
                                <MetricValue>{preference.value}</MetricValue>
                            </div>
                        </RouteCard>
                    ) : preference.name.toLowerCase().includes('airline') ? (
                        <RouteCard>
                            <RouteIcon>🏢</RouteIcon>
                            <div>
                                <MetricLabel>Airline</MetricLabel>
                                <MetricValue>{preference.value}</MetricValue>
                            </div>
                        </RouteCard>
                    ) : (
                        <RouteCard>
                            <RouteIcon>🎯</RouteIcon>
                            <div>
                                <MetricLabel>{addSpacesToTitleCase(preference.name)}</MetricLabel>
                                <MetricValue>{preference.value}</MetricValue>
                            </div>
                        </RouteCard>
                    )}
                </FlightGrid>
            </motion.div>

            {preference.metadata && Object.keys(preference.metadata).length > 0 && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, delay: 0.2 }}
                >
                    <Section>
                        <SectionTitle>Additional Details</SectionTitle>
                        <MetricGrid>
                            {Object.entries(preference.metadata)
                                .filter(([key]) => !['confidence', 'lastUpdated', 'source'].includes(key))
                                .map(([key, value]) => (
                                    <MetricCard key={key}>
                                        <MetricLabel>{addSpacesToTitleCase(key)}</MetricLabel>
                                        <MetricValue>{value}</MetricValue>
                                    </MetricCard>
                                ))}
                        </MetricGrid>
                    </Section>
                </motion.div>
            )}
        </>
    );

    const renderDiningView = () => (
        <>
            <Header>
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <CategoryLabel>Dining Preference</CategoryLabel>
                    <PreferenceName>{addSpacesToTitleCase(preference.name)}</PreferenceName>
                    <PreferenceValue>{preference.value}</PreferenceValue>
                </motion.div>
            </Header>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.1 }}
            >
                <DiningSection>
                    <SectionTitle>Details</SectionTitle>
                    {preference.name.toLowerCase().includes('cuisine') ? (
                        <CategoryTags>
                            {preference.value.split(',').map((cuisine: string, index: number) => (
                                <CuisineTag key={index}>{cuisine.trim()}</CuisineTag>
                            ))}
                        </CategoryTags>
                    ) : (
                        <MetricGrid>
                            <MetricCard>
                                <MetricLabel>{addSpacesToTitleCase(preference.name)}</MetricLabel>
                                <MetricValue>{preference.value}</MetricValue>
                            </MetricCard>
                        </MetricGrid>
                    )}
                </DiningSection>
            </motion.div>
        </>
    );

    const renderLodgingView = () => (
        <>
            <Header>
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <CategoryLabel>Lodging Preference</CategoryLabel>
                    <PreferenceName>{addSpacesToTitleCase(preference.name)}</PreferenceName>
                    <PreferenceValue>{preference.value}</PreferenceValue>
                </motion.div>
            </Header>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.1 }}
            >
                <LodgingSection>
                    <SectionTitle>Property Details</SectionTitle>
                    <MetricGrid>
                        <PropertyCard>
                            <MetricLabel>{addSpacesToTitleCase(preference.name)}</MetricLabel>
                            <MetricValue>{preference.value}</MetricValue>
                        </PropertyCard>
                        {preference.metadata && Object.entries(preference.metadata)
                            .filter(([key]) => !['confidence', 'lastUpdated', 'source'].includes(key))
                            .map(([key, value]) => (
                                <PropertyCard key={key}>
                                    <MetricLabel>{addSpacesToTitleCase(key)}</MetricLabel>
                                    <MetricValue>{value}</MetricValue>
                                </PropertyCard>
                            ))}
                    </MetricGrid>
                </LodgingSection>
            </motion.div>
        </>
    );

    const renderPurchaseView = () => (
        <>
            <Header>
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <CategoryLabel>Purchase Pattern</CategoryLabel>
                    <PreferenceName>{addSpacesToTitleCase(preference.name)}</PreferenceName>
                    <PreferenceValue>{preference.value}</PreferenceValue>
                </motion.div>
            </Header>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.1 }}
            >
                <PurchaseSection>
                    <SectionTitle>Spending Analysis</SectionTitle>
                    <MetricGrid>
                        <MetricCard>
                            <MetricLabel>Value</MetricLabel>
                            <MetricValue>{preference.value}</MetricValue>
                        </MetricCard>
                        {preference.metadata && Object.entries(preference.metadata)
                            .filter(([key]) => !['confidence', 'lastUpdated', 'source'].includes(key))
                            .map(([key, value]) => (
                                <MetricCard key={key}>
                                    <MetricLabel>{addSpacesToTitleCase(key)}</MetricLabel>
                                    <MetricValue>{value}</MetricValue>
                                </MetricCard>
                            ))}
                    </MetricGrid>
                </PurchaseSection>
            </motion.div>
        </>
    );

    const renderView = () => {
        switch (preference.categoryType) {
            case 'Memories':
                return renderMemoryView();
            case 'Flights':
                return renderFlightView();
            case 'Dining':
                return renderDiningView();
            case 'Lodging':
                return renderLodgingView();
            case 'Purchases':
                return renderPurchaseView();
            default:
                return (
                    <>
                        <Header>
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <CategoryLabel>{preference.categoryType}</CategoryLabel>
                                <PreferenceName>{addSpacesToTitleCase(preference.name)}</PreferenceName>
                                <PreferenceValue>{preference.value}</PreferenceValue>
                            </motion.div>
                        </Header>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3, delay: 0.1 }}
                        >
                            <Section>
                                <SectionTitle>Details</SectionTitle>
                                <MetricGrid>
                                    {preference.metadata && Object.entries(preference.metadata)
                                        .filter(([key]) => !['confidence', 'lastUpdated', 'source'].includes(key))
                                        .map(([key, value]) => (
                                            <MetricCard key={key}>
                                                <MetricLabel>{addSpacesToTitleCase(key)}</MetricLabel>
                                                <MetricValue>{value}</MetricValue>
                                            </MetricCard>
                                        ))}
                                </MetricGrid>
                            </Section>
                        </motion.div>
                    </>
                );
        }
    };

    return (
        <Container
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
        >
            {renderView()}
        </Container>
    );
};

export default PreferenceDetail;
