import React from 'react';
import { observer } from 'mobx-react';
import BasePage from 'components/common/BasePage';
import userMemoryStore from 'stores/MemoryStore';
type MemoryRecord = {
  content: string;
  created_at: string;
  categories?: string[];
  id: string;
};
class UserMemory extends React.Component {
  componentDidMount() {
    userMemoryStore.fetchMemories();
  }
  handleCategoryChange = (category: string | null) => {
    userMemoryStore.setCategoryFilter(category);
    userMemoryStore.fetchMemories();
  };
  renderCategoryFilters = () => {
    const categories = Array.from(new Set(userMemoryStore.memories.flatMap(memory => memory.categories || [])));
    return (
      <div className="category-filters">
        <button onClick={() => this.handleCategoryChange(null)}>All</button>
        {categories.map(category => (
          <button
            key={category}
            onClick={() => this.handleCategoryChange(category)}
            className={userMemoryStore.categoryFilter === category ? 'active' : ''}
          >
            {category}
          </button>
        ))}
      </div>
    );
  };
  renderMemories = () => {
    const { loading, filteredMemories } = userMemoryStore;
    if (loading) {
      return <div>Loading memories...</div>;
    }
    if (filteredMemories.length === 0) {
      return <div>No memories found</div>;
    }
    return (
      <ul className="memory-list">
        {filteredMemories.map((memory: MemoryRecord) => (
          <li key={memory.id} className="memory-item">
            <p className="memory-content">{memory.content}</p>
            <span className="memory-timestamp">{new Date(memory.created_at).toLocaleString()}</span>
            <span className="memory-categories">{memory.categories?.join(', ')}</span>
          </li>
        ))}
      </ul>
    );
  };
  render() {
    return (
      <BasePage>
        <div className="user-memory">
          <h2>User Memories</h2>
          {this.renderCategoryFilters()}
          {this.renderMemories()}
        </div>
      </BasePage>
    );
  }
}
export default observer(UserMemory);