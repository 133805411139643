import React from 'react';
import { inject, observer } from 'mobx-react';
import { Box, CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import { toast } from "react-toastify";
import { Text, HeadText } from 'components/common/Typography';
import Progress from 'components/common/Progress';


// Format numbers: exact <= 9999, then X.Xk format (1234 -> "1234", 12345 -> "12.3k")
const fmt = (n = 0) => n < 1e4 ? n.toString() : `${(n/1e3).toFixed(1)}k`;
const FmtNum: React.FC<{value?: number}> = ({value}) => <>{fmt(value)}</>;

type AccountStatsProps = {
    UserStore?: any;
    accountEmail: string;
}

type AccountStatsState = {
    totalEmails: number;
    processedEmails: number;
    flightExtractions: number;
    hotelStayExtractions: number;
    diningExtractions?: number;
    calendarEvents?: number;
    hasLoaded: boolean;
}

class AccountStats extends React.Component<AccountStatsProps, AccountStatsState> {
    state = {
        flightExtractions: 0,
        hotelStayExtractions: 0,
        processedEmails: 0,
        totalEmails: 0,
        diningExtractions: 0,
        calendarEvents: 0,
        hasLoaded: false
    };

    componentDidMount() {
        const { UserStore, accountEmail } = this.props;
        UserStore.getAccountData(accountEmail)
            .then(({ 
                flightExtractions, 
                hotelStayExtractions, 
                processedEmails, 
                totalEmails, 
                diningExtractions, 
                calendarEvents 
            }) => this.setState({
                flightExtractions,
                hotelStayExtractions,
                processedEmails,
                totalEmails,
                diningExtractions,
                calendarEvents,
                hasLoaded: true
            }))
            .catch(() => toast.error(
                <div>Adapter encountered a problem retrieving your account data.</div>,
                { theme: 'dark', position: 'bottom-right', icon: true }
            ));
    }

    render() {
        const { 
            hasLoaded,
            totalEmails,
            processedEmails,
            flightExtractions,
            hotelStayExtractions,
            diningExtractions,
            calendarEvents
        } = this.state;

        if (!hasLoaded) return <Progress />;

        const total = totalEmails + (calendarEvents || 0);
        const processed = processedEmails + (calendarEvents || 0);

        return (
            <div className="onboarding-stats mt-5">
                <div className="onboarding-progress">
                    <CircularProgress
                        capIsRound
                        thickness='6px'
                        size='150px'
                        max={total}
                        value={processed}
                        color='#7F56D9'
                    >
                        <CircularProgressLabel>
                            <HeadText><FmtNum value={processed}/></HeadText>
                            <Text>Out of <FmtNum value={total}/></Text>
                        </CircularProgressLabel>
                    </CircularProgress>
                </div>

                <div className="onboarding-text-container">
                    <div className="onboarding-text-row">
                        <Text>Flight reservations ingested</Text>
                        <Text><FmtNum value={flightExtractions}/></Text>
                    </div>
                    <div className="onboarding-text-row">
                        <Text>Hotel reservations ingested</Text>
                        <Text><FmtNum value={hotelStayExtractions}/></Text>
                    </div>
                    {diningExtractions > 0 && (
                        <div className="onboarding-text-row">
                            <Text>Dining reservations ingested</Text>
                            <Text><FmtNum value={diningExtractions}/></Text>
                        </div>
                    )}
                    <Box 
                        className="h-[1px] my-2" 
                        style={{ backgroundColor: 'var(--adapter-gray-light-200)' }} 
                    />
                    <div className="onboarding-text-row">
                        <Text>Total emails processed</Text>
                        <Text><FmtNum value={processedEmails}/></Text>
                    </div>
                    {calendarEvents > 0 && (
                        <div className="onboarding-text-row">
                            <Text>Calendar events ingested</Text>
                            <Text><FmtNum value={calendarEvents}/></Text>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default inject("UserStore")(observer(AccountStats));