import { makeObservable, action, observable } from "mobx";
import { io } from "socket.io-client";

import { isLocal } from "util/FeatureFlag";

export const SOCKET_KEYS = {
  graph_query: 'query_service::graph_query',
  load_jobs: 'agent_service::load_jobs',
  get_job: 'agent_service::get_job',
};

export type WebSocketMessage = {
  action?: string;
  componentId?: string;
  content?: any;
  error?: boolean;
}

class WebSocketStore {
  public socket: any = null;
  public connected: boolean = false;

  constructor() {
    makeObservable(this, {
      connected: observable,

      onConnectHandler: action,
      onDisconnectHandler: action,
    });
  }

  connect = () => {
    this.socket = io(isLocal() ? 'http://localhost:8000' : window.location.origin, { withCredentials: true });

    this.socket.on('connect', this.onConnectHandler);
    this.socket.on('disconnect', this.onDisconnectHandler);
    this.socket.on('message', this.onReceiveMessageHandler);
  }

  sendMessage = (data: WebSocketMessage) => {
    const msg = JSON.stringify(data);
    this.socket.emit(data.action, msg);
    console.log('Sent message to socket: ', data.action, msg)
  }

  assignMessageHandler = (event: string, handler: any) => {
    const wrappedHandler = (msg: string) => {
      return handler(this.onReceiveMessageHandler(msg));
    };

    this.socket.on(event, wrappedHandler);
  }

  onReceiveMessageHandler = (msg: string): WebSocketMessage => {
    try {
      const data: WebSocketMessage = JSON.parse(msg);
      console.log('Received data from socket: ', data);
      return data;
    }
    catch (e) {
      console.error('Caught an error: ', e);
      return { error: true };
    }
  }

  onConnectHandler = () => {
    this.connected = true;
    console.log('Connected to socket server');
  }

  onDisconnectHandler = () => {
    this.connected = false;
    console.log('Disconnected from socket server');
  }
}

const wss = new WebSocketStore();

export default wss;
