// /frontend/src/stores/AtlasStore.ts

import { makeObservable, action, observable, runInAction } from "mobx";
import { 
    determineTileType, 
    getTileTypeName, 
    getTileTypeIcon
} from '../constants/CategoryMappings';
import API from "util/API";

export interface AtlasPreference {
    name: string;
    value: any;
    category?: string;
    metadata?: {
        confidence?: number;
        lastUpdated?: string;
        source?: string;
        [key: string]: any;
    };
}

export interface AtlasCategory {
    id: string;
    name: string;
    icon: string;
    type: string;
    description: string;
    tileTypes: Array<{
        id: string;
        name: string;
        icon: string;
        count?: number;
    }>;
}

export interface AtlasResponse {
    category: string;
    preferences: AtlasPreference[];
    suggestedText: string;
}

export interface Memory {
    id: string;
    content: string;
    created_at: string;
    categories: string[];
    last_referenced?: string;
    updated_at?: string;
}

class AtlasStore {
    @observable categories: AtlasCategory[] = [
        {
            id: 'travel',
            name: 'Travel',
            icon: '✈️',
            type: 'Travel',
            description: 'Travel preferences and patterns',
            tileTypes: [
                { id: 'airline', name: 'Airlines', icon: '✈️' },
                { id: 'location', name: 'Locations', icon: '🏢' },
                { id: 'spend', name: 'Spend', icon: '💰' },
                { id: 'timing', name: 'Schedule', icon: '⏰' },
                { id: 'comfort', name: 'Comfort', icon: '💺' },
            ]
        },
        {
            id: 'dining',
            name: 'Dining',
            icon: '🍽️',
            type: 'Dining',
            description: 'Food and dining preferences',
            tileTypes: [
                { id: 'cuisine', name: 'Cuisines', icon: '🍳' },
                { id: 'spend', name: 'Spend', icon: '💰' },
                { id: 'timing', name: 'Schedule', icon: '⏰' },
                { id: 'location', name: 'Places', icon: '📍' }
            ]
        },
        {
            id: 'lodging',
            name: 'Lodging',
            icon: '🏨',
            type: 'Lodging',
            description: 'Accommodation preferences',
            tileTypes: [
                { id: 'accommodation', name: 'Property Types', icon: '🏨' },
                { id: 'comfort', name: 'Room & Amenities', icon: '🛏️' },
                { id: 'services', name: 'Services', icon: '🛎️' },
                { id: 'location', name: 'Location & Area', icon: '📍' },
                { id: 'spend', name: 'Rates & Budget', icon: '💰' },
                { id: 'loyalty', name: 'Hotel Programs', icon: '🎯' },
                { id: 'timing', name: 'Stay Patterns', icon: '⏰' },
                { id: 'social', name: 'Group & Events', icon: '👥' }
            ]
        },
        {
            id: 'memories',
            name: 'Memories',
            icon: '🧠',
            type: 'Memories',
            description: 'Your Personal Memory Bank',
            tileTypes: [] // Will be populated dynamically
        }
    ];

    @observable preferences: Record<string, AtlasResponse> = {};
    @observable isLoading: boolean = false;
    @observable error: string | null = null;
    @observable selectedCategory: string | null = null;
    @observable selectedTileType: string | null = null;
    @observable memories: Memory[] = [];
    @observable isLoadingMemories: boolean = false;
    @observable memoriesError: string | null = null;

    constructor() {
        makeObservable(this);
    }

    private normalizeCategory(category: string): string {
        const cleanCategory = category.replace('Category.', '');
        return cleanCategory
            .split('_')
            .map(word => 
                word.charAt(0).toUpperCase() + 
                word.slice(1).toLowerCase()
            )
            .join(' ');
    }

    private getMemoryIcon(category: string): string {
        switch (category) {
            case 'Travel':
                return '✈️';
            case 'Social':
                return '👥';
            case 'Personal':
                return '👤';
            case 'Flights':
                return '🛩️';
            case 'Dining':
                return '🍽️';
            case 'Lodging':
                return '🏨';
            default:
                return '📝';
        }
    }

    private getMemoryTileTypes() {
        const categoryMap = new Map<string, {
            id: string;
            name: string;
            icon: string;
            count: number;
        }>();

        this.memories.forEach(memory => {
            memory.categories.forEach(category => {
                const normalizedCategory = this.normalizeCategory(category);
                
                if (!categoryMap.has(normalizedCategory)) {
                    const icon = this.getMemoryIcon(normalizedCategory);
                    categoryMap.set(normalizedCategory, {
                        id: normalizedCategory,
                        name: normalizedCategory,
                        icon,
                        count: 1
                    });
                } else {
                    categoryMap.get(normalizedCategory)!.count++;
                }
            });
        });

        return Array.from(categoryMap.values())
            .sort((a, b) => b.count - a.count)
            .map(item => ({
                id: item.id,
                name: `${item.name} (${item.count})`,
                icon: item.icon,
                count: item.count
            }));
    }

    @action
    setSelectedCategory(category: string | null) {
        this.selectedCategory = category;
    }

    @action
    setSelectedTileType(tileType: string | null) {
        this.selectedTileType = tileType;
    }

    @action
    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    @action
    setError(error: string | null) {
        this.error = error;
    }

    @action
    setPreferences(category: string, data: AtlasResponse) {
        const formattedData: AtlasResponse = {
            category: data.category,
            preferences: data.preferences.map(pref => ({
                name: pref.name,
                value: pref.value,
                category: data.category,
                metadata: {
                    lastUpdated: pref.metadata?.lastUpdated,
                    source: pref.metadata?.source,
                    ...pref.metadata
                }
            })),
            suggestedText: data.suggestedText || ''
        };
        this.preferences[category] = formattedData;
    }

    @action
    async fetchMemories(category?: string): Promise<Memory[]> {
        this.isLoadingMemories = true;
        this.memoriesError = null;

        try {
            const url = category ? 
                `/api/prompt/memories?category=${encodeURIComponent(category)}` : 
                '/api/prompt/memories';
            
            const response = await API.get(url);
            const data = await response.json();

            runInAction(() => {
                this.memories = data.memories.map((memory: any) => ({
                    ...memory,
                    categories: memory.categories.map((category: string) => 
                        this.normalizeCategory(category)
                    )
                }));

                // Update the memories category tileTypes
                const memoryCategory = this.categories.find(c => c.id === 'memories');
                if (memoryCategory) {
                    memoryCategory.tileTypes = this.getMemoryTileTypes();
                }

                this.isLoadingMemories = false;
            });

            return this.memories;
        } catch (error) {
            const errorMessage = error instanceof Error ? 
                error.message : 
                'Failed to fetch memories';
            
            runInAction(() => {
                this.memoriesError = errorMessage;
                this.isLoadingMemories = false;
            });

            throw error;
        }
    }

    @action
    getFilteredMemories(category: string, tileType?: string): Memory[] {
        if (!tileType) {
            return this.memories;
        }

        const normalizedTileType = this.normalizeCategory(tileType);
        return this.memories.filter(memory => 
            memory.categories.some(cat => 
                this.normalizeCategory(cat) === normalizedTileType
            )
        );
    }

    @action
    async fetchAtlasPreference(category: string): Promise<AtlasResponse> {
        this.setLoading(true);
        this.setError(null);

        try {
            const response = await API.get(`/api/user/atlas/${category}`);
            const rawData = await response.json();

            const formattedData: AtlasResponse = {
                category: category,
                preferences: rawData.preferences.map((pref: any) => ({
                    name: pref.name,
                    value: pref.value,
                    category: category,
                    metadata: {
                        confidence: pref.metadata?.confidence || 0,
                        lastUpdated: pref.metadata?.lastUpdated || new Date().toISOString(),
                        source: pref.metadata?.source || 'Atlas',
                        ...pref.metadata
                    }
                })),
                suggestedText: rawData.suggestedText || ''
            };

            runInAction(() => {
                this.setPreferences(category, formattedData);
                this.setLoading(false);
            });

            return formattedData;
        } catch (error) {
            const errorMessage = error instanceof Error ? 
                error.message : 
                'Failed to fetch preferences';
            
            runInAction(() => {
                this.setError(errorMessage);
                this.setLoading(false);
            });

            throw error;
        }
    }

    @action
    async updateAtlasPreference(category: string, preferenceId: string, value: any): Promise<void> {
        try {
            await API.put(`/api/user/atlas/${category}/${preferenceId}`, { value });
            await this.fetchAtlasPreference(category);
        } catch (error) {
            const errorMessage = error instanceof Error ? 
                error.message : 
                'Failed to update preference';
            this.setError(errorMessage);
            throw error;
        }
    }

    getPreferences(category: string): AtlasResponse {
        return this.preferences[category] || {
            category,
            preferences: [],
            suggestedText: ''
        };
    }

    getCategoryById(id: string): AtlasCategory | undefined {
        return this.categories.find(category => category.id === id);
    }

    getTileTypes(categoryId: string): AtlasCategory['tileTypes'] {
        const category = this.getCategoryById(categoryId);
        if (category?.type === 'Memories') {
            return this.getMemoryTileTypes();
        }
        return category?.tileTypes || [];
    }

    getCurrentTileTypes = () => {
        const category = this.categories.find(
            c => c.id === this.selectedCategory
        );

        if (category?.type === 'Memories') {
            return category.tileTypes;
        }

        if (category?.type) {
            const cachedData = this.preferences[category.type]?.preferences;
            if (cachedData) {
                const tileTypes = new Map();
                cachedData.forEach(pref => {
                    const tileType = determineTileType(pref.name);
                    if (!tileTypes.has(tileType)) {
                        tileTypes.set(tileType, {
                            id: tileType,
                            name: getTileTypeName(tileType),
                            icon: getTileTypeIcon(tileType),
                            count: 0
                        });
                    }
                    tileTypes.get(tileType).count++;
                });
                return Array.from(tileTypes.values());
            }
        }

        return category?.tileTypes || [];
    };

    clearPreferences() {
        this.preferences = {};
        this.selectedCategory = null;
        this.selectedTileType = null;
        this.memories = [];
    }
}

export default new AtlasStore();
