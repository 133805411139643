import React from 'react';
import { inject, observer } from 'mobx-react';

import Pill from 'components/common/Pill';
import { ResponseBase, ComprehensionView } from 'models/comprehension/response/base';
import Constraints from 'models/comprehension/constraints';
import PromptFeedback from './PromptFeedback';

import './PromptComprehension.scss';

const PillPopover = ({ value, label = '', inferred = false }) => {
    if (value === null || value === undefined) {
        return <></>;
    }

    // if (inferred) {
    //     return (
    //         <Popover trigger={<Pill highlighted>{value}</Pill>}>
    //             <>
    //                 <h2>How did we infer this?</h2>
    //                 <p>{inferredReason(label)}</p>
    //             </>
    //         </Popover>
    //     );
    // }

    return <Pill>{value}</Pill>;
}

type PromptComprehensionProps = {
    comprehension: ResponseBase;
    PromptStore?: any;
}

class PromptComprehension extends React.Component<PromptComprehensionProps, {}> {
    renderComprehensionView = (view: ComprehensionView) => {
        return (
            <>
                {view.text && <p>{view.text}</p>}
                {view.attrs && Object.entries(view.attrs).map(([key, values]) => (
                    <div key={key} className="attribute-group">
                        <span className="category">{key}: </span>
                        {values.map((value, index) => (
                            <PillPopover key={`${key}-${index}`} value={value} />
                        ))}
                    </div>
                ))}
            </>
        );
    }

    render() {
        const { comprehension } = this.props;

        const promptResponse: ResponseBase = comprehension;
        // @ts-ignore
        const constraints: Constraints | undefined = promptResponse?.constraints;
        const comprehensionView = promptResponse?.render(constraints);

        return (
            <div className="adapter-prompt-comprehension">
                {this.renderComprehensionView(comprehensionView)}

                {<PromptFeedback comprehension={comprehension} />}
            </div>
        );
    }
}

export default inject('PromptStore')(observer(PromptComprehension));