// /frontend/src/pages/atlas/index.tsx
import React from "react";
import { toast } from "react-toastify";
import { inject, observer } from "mobx-react";
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import API from "util/API";

// Store imports
import atlasStore from "../../stores/AtlasStore";

// Component imports
import { AtlasLoader } from "components/common/Loading/AtlasLoader";
import AuthenticatedPage from "components/common/AuthenticatedPage";
import BasePage from 'components/common/BasePage';
import { CategoryList } from './CategoryList';
import { TileTypeList } from './TileTypeList';
import { PreferenceList } from './PreferenceList';
import { PreferenceDetail } from './PreferenceDetail';
import { DossierView } from '../../components/DossierView';
import { DossierLoader } from "components/common/Loading/DossierLoader";




import {
    determineTileType,
    getTileTypeName,
    getTileTypeIcon,
} from '../../constants/CategoryMappings';

import './index.scss';

const scrollToTop = () => {
    document.querySelector('.adapter-basepage-inner').scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}



// Styled Components
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 18px;
  min-height: 100vh;
  background-color: #edebfa;
  width: 100%;
  max-width: none; // Remove any max-width constraint
`;


// Back button with animation
const BackButton = styled(motion.button)`
  border: none;
  background: none;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-family: "Rand Medium", sans-serif;
  transition: all 0.2s ease;
  
  &::before {
    content: '←';
    transition: transform 0.2s ease;
  }

  &:hover {
    color: #333;
    &::before {
      transform: translateX(-4px);
    }
  }
`;

// Animated section container
const Section = styled(motion.div)<SectionProps>`
  position: relative;
  margin-bottom: 24px;
  width: 100%;
  max-width: none;
  background-color: #edebfa;
  min-height: ${props => props.isLoading ? '100vh' : 'auto'};
`;

// Breadcrumb navigation container
const BreadcrumbNav = styled.nav`
  padding: 0 24px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BreadcrumbItem = styled.span<{ isActive?: boolean }>`
  color: ${props => props.isActive ? '#333' : '#666'};
  font-size: 14px;
  cursor: ${props => props.isActive ? 'default' : 'pointer'};
  
  &:hover {
    color: ${props => props.isActive ? '#333' : '#000'};
  }
`;

const BreadcrumbSeparator = styled.span`
  color: #999;
  margin: 0 4px;
`;

// Animation variants
const pageTransition = {
    initial: { opacity: 0, x: 20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -20 },
    transition: { duration: 0.3 }
};

export  function LoadingSpinner() {
    return (
      <div className="w-10 h-10 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin" />
    );
  }

// Interfaces for Atlas data
interface SectionProps {
  isLoading?: boolean;
}

interface AtlasPreference {
    id: string;
    name: string;
    value: any;
    category: string;
    subCategory?: string;
    metadata?: Record<string, any>;
    confidence?: number;
    lastUpdated?: string;
    source?: string;
}

// In AtlasPage, update the AtlasCategoryData interface
interface AtlasCategoryData {
    category: string;
    preferences: AtlasPreference[];
    metadata?: {
        lastUpdated?: string;
        confidence?: number;
        source?: string;
    };
    suggestedText?: string; // Add this line
}


interface AtlasCache {
    [key: string]: {
        data: AtlasCategoryData;
        timestamp: number;
        expiresAt: number;
    };
}

interface AtlasPageState {
    selectedCategory: string | null;
    selectedTileType: string | null;
    selectedPreference: string | null;
    selectedCategoryType: string | null;
    breadcrumbs: string[];
    navigationHistory: Array<{ type: 'category' | 'tiletype' | 'preference', id: string }>;
    isLoading: boolean;
    isLoadingMemories: boolean;
    isLoadingPreferences: boolean;
    retryCount: number;
    atlasCache: AtlasCache;
    memories: Array<{
        id: string;
        categoryId: string;
        categoryType: string;
        tileType: string;
        name: string;
        value: string;
        metadata?: {
            date?: string;
            source?: string;
            confidence?: number;
            [key: string]: any;
        };
    }>;
    error: string | null;
    lastUpdated: Record<string, number>;
    analysisData?: {
        opening_insight: string;
        pattern_weaving: { [key: string]: string[] };
        behavioral_signatures: string[];
        key_insight: string;
        illuminating_moments: string;
        adaptive_patterns: string;
        preference_architecture: string;
    };
}

interface AtlasPageProps {
    AtlasStore?: {
        fetchAtlasPreference: (category: string) => Promise<AtlasCategoryData>;
        updateAtlasPreference: (category: string, preferenceId: string, value: any) => Promise<void>;
        fetchMemories: (category?: string) => Promise<any[]>;
        getFilteredMemories: (category: string, tileType?: string) => any[];
        isLoadingMemories: boolean;
        memoriesError: string | null;
        memories: any[];
    };
}

interface UnderstandingItem {
    title: string;
    value: any;
    type: "text" | "percentage" | "memory";
}

interface Memory {
    id: string;
    content: string;
    created_at: string;
    categories: string[];
    last_referenced?: string;
    updated_at?: string;
}

interface PreferenceItem {
    id: string;
    categoryId: string;
    categoryType: string;
    tileType: string;
    name: string;
    value: any;
    metadata?: {
        confidence?: number;
        lastUpdated?: string;
        source?: string;
        created_at?: string;
        last_referenced?: string;
        updated_at?: string;
        categories?: string[];
        [key: string]: any;
    };
}

// Constants
const CACHE_DURATION = 1000 * 60 * 15; // 15 minutes
const MAX_RETRY_ATTEMPTS = 1;
const RETRY_DELAY = 1000; // 1 second

// Sample data import (keep your existing sample data)
export const sampleData = {
    categories: [
        {
            id: '1',
            type: 'Flights',
            name: 'Flights',
            icon: '✈️',
            description: 'Travel Preferences',
            tileTypes: [
                { id: 'airline', name: 'Airlines', icon: '✈️' },
                { id: 'price', name: 'Price & Budget', icon: '💰' },
                { id: 'timing', name: 'Time & Schedule', icon: '⏰' },
                { id: 'location', name: 'Airports & Routes', icon: '📍' },
                { id: 'comfort', name: 'Comfort & Class', icon: '💺' },
                { id: 'loyalty', name: 'Loyalty Programs', icon: '🎯' }
            ]
        },
        {
            id: '2',
            type: 'Dining',
            name: 'Dining',
            icon: '🍽️',
            description: 'Dining Preferences',
            tileTypes: [
                { id: 'cuisine', name: 'Cuisine Types', icon: '🍳' },
                { id: 'price', name: 'Price Range', icon: '💰' },
                { id: 'dietary', name: 'Dietary Needs', icon: '🥗' },
                { id: 'atmosphere', name: 'Atmosphere', icon: '🌟' },
                { id: 'location', name: 'Location & Distance', icon: '📍' },
                { id: 'timing', name: 'Dining Times', icon: '⏰' }
            ]
        },
        {
            id: '3',
            type: 'Lodging',
            name: 'Lodging',
            icon: '🏨',
            description: 'Accommodation Preferences',
            tileTypes: [
                { id: 'accommodation', name: 'Property Types', icon: '🏨' },
                { id: 'comfort', name: 'Amenities', icon: '🛏️' },
                { id: 'services', name: 'Services', icon: '🛎️' },
                { id: 'location', name: 'Location & Area', icon: '📍' },
                { id: 'price', name: 'Rates & Budget', icon: '💰' },
                { id: 'loyalty', name: 'Hotel Programs', icon: '🎯' },
                { id: 'timing', name: 'Stay Patterns', icon: '⏰' },
                { id: 'social', name: 'Group & Events', icon: '👥' }
            ]
        },
        {
            id: '4',
            type: 'Purchases',
            name: 'Purchases',
            icon: '🛍️',
            description: 'Shopping Patterns',
            tileTypes: [
                { id: 'merchants', name: 'Top Merchants', icon: '🏪' },
                { id: 'categories', name: 'Shopping Categories', icon: '📋' },
                { id: 'spending', name: 'Spending Patterns', icon: '💰' },
                { id: 'timing', name: 'Shopping Times', icon: '⏰' },
                { id: 'subscriptions', name: 'Subscriptions', icon: '🔄' },
                { id: 'frequency', name: 'Purchase Frequency', icon: '📊' },
                { id: 'seasonal', name: 'Seasonal Trends', icon: '📅' },
                { id: 'digital', name: 'Digital vs Physical', icon: '💻' }
            ]
        },
        {
            id: '5',
            type: 'Memories',
            name: 'Memories',
            icon: '🧠',
            description: 'Your Personal Memory',
            tileTypes: [
                { id: 'TRAVEL', name: 'Travel', icon: '✈️' },
                { id: 'SOCIAL', name: 'Social', icon: '👥' },
                { id: 'PERSONAL', name: 'Personal', icon: '👤' },
                { id: 'FLIGHTS', name: 'Flights', icon: '🛩️' },
                { id: 'DINING', name: 'Dining', icon: '🍽️' },
                { id: 'LODGING', name: 'Lodging', icon: '🏨' }
            ]
        },
        {
            id: '6',
            type: 'Dossier',
            name: 'Dossier',
            icon: '📊',
            description: 'Personal Analysis Report',
            tileTypes: [
                { id: 'analysis', name: 'Full Analysis', icon: '📋' }
            ]
        },

    ],
    preferences: [
        // Travel Preferences
        {
            id: 'travel-1',
            categoryId: '1',
            categoryType: 'Travel',
            tileType: 'airline',
            name: 'Primary Airline',
            value: 'Delta Airlines',
            location: { code: 'JFK' },
            metadata: { membership: 'Diamond Medallion' }
        },
        {
            id: 'travel-2',
            categoryId: '1',
            categoryType: 'Travel',
            tileType: 'airline',
            name: 'Secondary Airline',
            value: 'United Airlines',
            location: { code: 'AUS' },
            metadata: { membership: 'Gold' }
        },
        {
            id: 'travel-3',
            categoryId: '1',
            categoryType: 'Travel',
            tileType: 'price',
            name: 'Average Ticket Budget',
            value: '$750',
            metadata: { scope: 'Domestic' }
        },
        {
            id: 'travel-4',
            categoryId: '1',
            categoryType: 'Travel',
            tileType: 'timing',
            name: 'Preferred Departure',
            value: '8am - 11am',
            metadata: { days: 'Weekday' }
        },
        {
            id: 'travel-5',
            categoryId: '1',
            categoryType: 'Travel',
            tileType: 'comfort',
            name: 'Seat Preference',
            value: 'Window',
            metadata: { position: 'Forward Cabin' }
        },

        // Dining Preferences
        {
            id: 'food-1',
            categoryId: '3',
            categoryType: 'Food',
            tileType: 'cuisine',
            name: 'Favorite Cuisine',
            value: 'Italian',
            metadata: { specifics: 'Northern Italian' }
        },
        {
            id: 'food-2',
            categoryId: '3',
            categoryType: 'Food',
            tileType: 'price',
            name: 'Dining Budget',
            value: '$$$',
            metadata: { mealType: 'Dinner' }
        },
        {
            id: 'food-3',
            categoryId: '3',
            categoryType: 'Food',
            tileType: 'dietary',
            name: 'Dietary Restriction',
            value: 'Gluten-Free',
            metadata: { severity: 'Strict' }
        },
    ]
};

class AtlasPage extends React.Component<AtlasPageProps, AtlasPageState> {
    // Cache management
    private cacheTimeouts: Record<string, NodeJS.Timeout> = {};
    private retryTimeouts: Record<string, NodeJS.Timeout> = {};

    state: AtlasPageState = {
        selectedCategory: null,
        selectedTileType: null,
        selectedPreference: null,
        selectedCategoryType: null,
        breadcrumbs: [],
        navigationHistory: [],
        isLoading: false,
        isLoadingMemories: false,
        isLoadingPreferences: false,
        retryCount: 0,
        atlasCache: {},
        memories: [],
        error: null,
        lastUpdated: {}
    };

    componentDidMount() {
        this.loadInitialData();
    }

    componentWillUnmount() {
        Object.values(this.cacheTimeouts).forEach(clearTimeout);
        Object.values(this.retryTimeouts).forEach(clearTimeout);
    }

    loadInitialData = async () => {
        try {
            this.setState({ isLoading: true });

            // Define categories to load, explicitly excluding 'Dossier' and 'Memories'
            const categories = sampleData.categories
                .filter(c => !['Dossier', 'Memories'].includes(c.type))
                .map(c => c.type);

            // Log categories being loaded
            console.log('Loading initial data for categories:', categories);

            const results = await Promise.allSettled(
                categories
                    .filter(category => this.shouldFetchCategory(category))
                    .map(async category => {
                        try {
                            const data = await this.fetchAtlasData(category, true);
                            return {
                                status: 'fulfilled',
                                category,
                                data
                            };
                        } catch (error) {
                            console.error(`Failed to load category ${category}:`, error);
                            return {
                                status: 'rejected',
                                category,
                                reason: error
                            };
                        }
                    })
            );

            // Process results
            const errors = results
                .filter(result => result.status === 'rejected')
                .map(result => ({
                    category: (result as PromiseRejectedResult & { category: string }).category,
                    error: (result as PromiseRejectedResult).reason
                }));

            const successes = results
                .filter(result => result.status === 'fulfilled')
                .map(result => ({
                    category: (result as PromiseFulfilledResult<{ category: string, data: any }>).value.category,
                    data: (result as PromiseFulfilledResult<{ category: string, data: any }>).value.data
                }));

            // Update state based on results
            this.setState({
                isLoading: false,
                error: errors.length > 0 ? `Failed to load ${errors.length} categories` : null
            });

            // Detailed logging
            if (successes.length > 0) {
                console.log(`Successfully loaded ${successes.length} categories:`, 
                    successes.map(s => s.category));
            }

            if (errors.length > 0) {
                console.error(`Failed to load ${errors.length} of ${categories.length} categories:`, 
                    errors.map(e => ({
                        category: e.category,
                        error: e.error instanceof Error ? e.error.message : 'Unknown error'
                    }))
                );
                toast.error(`Failed to load ${errors.length} categories`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }

            // If all categories failed to load
            if (errors.length === categories.length) {
                toast.error('Failed to load any categories. Please try again later.', {
                    position: "top-right",
                    autoClose: false,
                    closeOnClick: true,
                });
            }

            // If some categories loaded successfully
            if (successes.length > 0 && errors.length > 0) {
                toast.warn(
                    `Loaded ${successes.length} categories, but ${errors.length} failed. Some data may be incomplete.`, 
                    {
                        position: "top-right",
                        autoClose: 5000,
                    }
                );
            }

            // If all categories loaded successfully
            if (successes.length === categories.length) {
                console.log('All categories loaded successfully');
            }

        } catch (error) {
            // Handle critical failures
            console.error('Critical failure in loadInitialData:', error);
            this.setState({
                error: 'Failed to load initial data',
                isLoading: false
            });
            
            toast.error(
                'Failed to load initial data. Please refresh the page or try again later.',
                {
                    position: "top-right",
                    autoClose: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                }
            );
        } finally {
            // Ensure loading state is always cleared
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
        }
    };

    private parseAnalysisText = (analysisData: any) => {
        // Early return if data is invalid
        if (!analysisData || !analysisData.user_analysis) {
            console.warn('Invalid analysis data structure received');
            return {
                opening_insight: '',
                pattern_weaving: {},
                behavioral_signatures: [],
                key_insight: '',
                illuminating_moments: '',
                adaptive_patterns: '',
                preference_architecture: ''
            };
        }

        const {
            opening_insight,
            pattern_weaving,
            behavioral_signatures,
            key_insight,
            illuminating_moments,
            adaptive_patterns,
            preference_architecture
        } = analysisData.user_analysis;

        // Transform the pattern_weaving into the expected format
        const transformedPatternWeaving = {
            'Travel & Lifestyle': [pattern_weaving || ''],
            'Decision Making': [preference_architecture || ''],
            'Seasonal Patterns': [adaptive_patterns || ''],
            'Behavioral Insights': [behavioral_signatures || ''],
        };

        return {
            opening_insight: opening_insight || '',
            pattern_weaving: transformedPatternWeaving,
            // Don't wrap behavioral_signatures in an array since it's already an array
            behavioral_signatures: behavioral_signatures || [],
            key_insight: key_insight || '',
            illuminating_moments: illuminating_moments || '',
            adaptive_patterns: adaptive_patterns || '',
            preference_architecture: preference_architecture || ''
        };
    };




    private fetchAnalysis = async () => {
        try {
            const response = await API.get('/api/prompt/analysis?force_update=true');
            const data = await response.json();
            
            // Parse the response into our expected format
            return this.parseAnalysisText(data);
        } catch (error) {
            console.error('Failed to fetch analysis:', error);
            throw error;
        }
    };


    private shouldFetchCategory = (category: string): boolean => {
        const cachedData = this.state.atlasCache[category];
        if (!cachedData) return true;
        const now = Date.now();
        return now >= cachedData.expiresAt;
    };

    private setCachedData = (category: string, data: AtlasCategoryData) => {
        const now = Date.now();
        const newCache = {
            ...this.state.atlasCache,
            [category]: {
                data,
                timestamp: now,
                expiresAt: now + CACHE_DURATION
            }
        };

        if (this.cacheTimeouts[category]) {
            clearTimeout(this.cacheTimeouts[category]);
        }

        this.cacheTimeouts[category] = setTimeout(() => {
            this.setState(prevState => ({
                atlasCache: {
                    ...prevState.atlasCache,
                    [category]: undefined
                }
            }));
        }, CACHE_DURATION);

        this.setState({
            atlasCache: newCache,
            lastUpdated: {
                ...this.state.lastUpdated,
                [category]: now
            }
        });
    };

    fetchAtlasData = async (category: string, silent: boolean = false) => {
        if (!silent) {
            this.setState({ isLoadingPreferences: true });
        }

        const attempt = async (retryCount: number = 0) => {
            try {
                const data = await this.props.AtlasStore.fetchAtlasPreference(category);
                this.setCachedData(category, data);

                if (!silent) {
                    this.setState({
                        isLoadingPreferences: false,
                        retryCount: 0
                    });
                }

                return data;
            } catch (error) {
                if (retryCount < MAX_RETRY_ATTEMPTS) {
                    if (this.retryTimeouts[category]) {
                        clearTimeout(this.retryTimeouts[category]);
                    }

                    this.retryTimeouts[category] = setTimeout(() => {
                        attempt(retryCount + 1);
                    }, RETRY_DELAY * Math.pow(2, retryCount));

                    if (!silent) {
                        this.setState({ retryCount: retryCount + 1 });
                    }
                } else {
                    if (!silent) {
                        this.setState({
                            isLoadingPreferences: false,
                            error: 'Failed to load preferences after multiple attempts',
                            retryCount: 0
                        });
                    }
                    throw error;
                }
            }
        };

        return attempt();
    };

    handleCategorySelect = async (id: string) => {
        try {
            // Set loading state and category immediately
            this.setState({
                isLoading: true,
                selectedCategory: id
            });

            console.log('Selected Category:', id);

            const category = sampleData.categories.find(c => c.id === id);
            if (!category) throw new Error('Category not found');

            // Handle different category types
            if (category.type === 'Dossier') {
                console.log('Loading Dossier...');
                try {
                    const analysisData = await this.fetchAnalysis();
                    this.setState({
                        selectedCategoryType: 'Dossier',
                        selectedTileType: 'analysis',
                        selectedPreference: 'dossier-analysis',
                        analysisData,
                        breadcrumbs: [category.name],
                        navigationHistory: [
                            { type: 'category', id }
                        ],
                        isLoading: false,
                        error: null,
                        isLoadingPreferences: false,
                        retryCount: 0
                    }, () => {
                        scrollToTop();
                        console.log('Dossier analysis loaded successfully');
                    });
                    return;
                } catch (error) {
                    console.error('Failed to load Dossier analysis:', error);
                    toast.error('Failed to load analysis data. Please try again.');
                    this.setState({
                        error: 'Failed to load analysis data',
                        isLoading: false,
                        isLoadingPreferences: false
                    });
                    return;
                }
            }

            // Handle Memories category
            if (category.type === 'Memories') {
                try {
                    await this.props.AtlasStore?.fetchMemories();
                    this.setState({
                        selectedCategoryType: 'Memories',
                        selectedTileType: null,
                        selectedPreference: null,
                        breadcrumbs: [category.name],
                        navigationHistory: [...this.state.navigationHistory, { type: 'category', id }],
                        isLoading: false,
                        error: null,
                        isLoadingPreferences: false,
                        retryCount: 0
                    }, scrollToTop);
                } catch (error) {
                    console.error('Failed to load Memories:', error);
                    toast.error('Failed to load memories. Please try again.');
                    this.setState({
                        error: 'Failed to load memories',
                        isLoading: false,
                        isLoadingPreferences: false
                    });
                }
                return;
            }

            // Handle standard categories
            try {
                if (this.shouldFetchCategory(category.type)) {
                    await this.fetchAtlasData(category.type);
                }

                this.setState({
                    selectedCategoryType: category.type,
                    selectedTileType: null,
                    selectedPreference: null,
                    breadcrumbs: [category.name],
                    navigationHistory: [...this.state.navigationHistory, { type: 'category', id }],
                    isLoading: false,
                    error: null,
                    isLoadingPreferences: false,
                    retryCount: 0
                }, () => {
                    scrollToTop();
                    console.log(`Category ${category.type} loaded successfully`);
                });

            } catch (error) {
                console.error(`Failed to load category ${category.type}:`, error);
                const errorMessage = error instanceof Error ? error.message : 'Failed to load category';
                toast.error(`Failed to load ${category.name}. Please try again.`);
                this.setState({
                    error: errorMessage,
                    isLoading: false,
                    isLoadingPreferences: false
                });
            }

        } catch (error) {
            // Handle any unexpected errors
            console.error('Unexpected error in handleCategorySelect:', error);
            const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
            toast.error(errorMessage);
            this.setState({
                error: errorMessage,
                isLoading: false,
                isLoadingPreferences: false,
                retryCount: 0
            });
        } finally {
            // Ensure loading states are cleared if they haven't been already
            if (this.state.isLoading || this.state.isLoadingPreferences) {
                this.setState({
                    isLoading: false,
                    isLoadingPreferences: false
                });
            }
        }
    };






    handleTileTypeSelect = async (typeId: string) => {
        try {
            this.setState({ isLoading: true });

            const category = sampleData.categories.find(c => c.id === this.state.selectedCategory);
            const tileType = this.getCurrentTileTypes().find(t => t.id === typeId);

            if (!tileType) throw new Error('Tile type not found');

            if (category?.type === 'Memories') {
                // No need to fetch again as we already have all memories
                // Just filter them by category in getCurrentPreferences
            } else {
                if (category && this.shouldFetchCategory(category.type)) {
                    await this.fetchAtlasData(category.type);
                }
            }

            this.setState(prevState => ({
                selectedTileType: typeId,
                selectedPreference: null,
                breadcrumbs: [...prevState.breadcrumbs, tileType.name],
                navigationHistory: [...prevState.navigationHistory, { type: 'tiletype', id: typeId }],
                isLoading: false,
                error: null
            }), scrollToTop);
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Failed to load tile type';
            toast.error(errorMessage);
            this.setState({
                error: errorMessage,
                isLoading: false
            });
        }
    };

    handlePreferenceSelect = async (id: string) => {
        try {
            this.setState({ isLoading: true });

            const preference = this.getCurrentPreferences().find(p => p.id === id);
            if (!preference) {
                throw new Error('Preference not found');
            }

            // Special handling for Dossier analysis
            if (id === 'dossier-analysis') {
                try {
                    const analysisData = await this.fetchAnalysis();
                    this.setState({
                        selectedPreference: id,
                        analysisData,
                        breadcrumbs: [...this.state.breadcrumbs, 'Analysis Details'],
                        navigationHistory: [...this.state.navigationHistory, { type: 'preference', id }],
                        isLoading: false,
                        error: null,
                        isLoadingPreferences: false,
                        retryCount: 0
                    } as AtlasPageState, () => {
                        scrollToTop();
                        console.log('Dossier analysis details loaded successfully');
                    });
                    return;
                } catch (error) {
                    console.error('Failed to load Dossier analysis details:', error);
                    toast.error('Failed to load analysis details. Please try again.');
                    this.setState({
                        error: 'Failed to load analysis details',
                        isLoading: false,
                        isLoadingPreferences: false
                    });
                    return;
                }
            }

            // Handle Memory preferences
            if (this.state.selectedCategory === '5') {
                try {
                    const memories = this.props.AtlasStore?.memories || [];
                    const memory = memories.find(m => m.id === id);
                    
                    if (!memory) {
                        throw new Error('Memory not found');
                    }

                    this.setState({
                        selectedPreference: id,
                        breadcrumbs: [...this.state.breadcrumbs, memory.content.substring(0, 30) + '...'],
                        navigationHistory: [...this.state.navigationHistory, { type: 'preference', id }],
                        isLoading: false,
                        error: null,
                        isLoadingPreferences: false,
                        retryCount: 0
                    } as AtlasPageState, () => {
                        scrollToTop();
                        console.log('Memory details loaded successfully');
                    });
                    return;
                } catch (error) {
                    console.error('Failed to load memory details:', error);
                    toast.error('Failed to load memory details. Please try again.');
                    this.setState({
                        error: 'Failed to load memory details',
                        isLoading: false,
                        isLoadingPreferences: false
                    });
                    return;
                }
            }

            // Handle Atlas preferences
            if (id.startsWith('atlas-')) {
                try {
                    const category = sampleData.categories.find(c => c.id === this.state.selectedCategory);
                    if (category && this.shouldFetchCategory(category.type)) {
                        await this.fetchAtlasData(category.type);
                    }

                    this.setState({
                        selectedPreference: id,
                        breadcrumbs: [...this.state.breadcrumbs, preference.name],
                        navigationHistory: [...this.state.navigationHistory, { type: 'preference', id }],
                        isLoading: false,
                        error: null,
                        isLoadingPreferences: false,
                        retryCount: 0
                    } as AtlasPageState, () => {
                        scrollToTop();
                        console.log('Atlas preference details loaded successfully');
                    });
                    return;
                } catch (error) {
                    console.error('Failed to load Atlas preference details:', error);
                    toast.error('Failed to load preference details. Please try again.');
                    this.setState({
                        error: 'Failed to load preference details',
                        isLoading: false,
                        isLoadingPreferences: false
                    });
                    return;
                }
            }

            // Handle standard preferences
            try {
                this.setState({
                    selectedPreference: id,
                    breadcrumbs: [...this.state.breadcrumbs, preference.name],
                    navigationHistory: [...this.state.navigationHistory, { type: 'preference', id }],
                    isLoading: false,
                    error: null,
                    isLoadingPreferences: false,
                    retryCount: 0
                } as AtlasPageState, () => {
                    scrollToTop();
                    console.log('Standard preference details loaded successfully');
                });

            } catch (error) {
                console.error('Failed to load preference details:', error);
                const errorMessage = error instanceof Error ? error.message : 'Failed to load preference details';
                toast.error(errorMessage);
                this.setState({
                    error: errorMessage,
                    isLoading: false,
                    isLoadingPreferences: false
                });
            }

        } catch (error) {
            // Handle any unexpected errors
            console.error('Unexpected error in handlePreferenceSelect:', error);
            const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
            toast.error(errorMessage);
            this.setState({
                error: errorMessage,
                isLoading: false,
                isLoadingPreferences: false,
                retryCount: 0
            });
        } finally {
            // Ensure loading states are cleared if they haven't been already
            if (this.state.isLoading || this.state.isLoadingPreferences) {
                this.setState({
                    isLoading: false,
                    isLoadingPreferences: false
                });
            }
        }
    };


    handleBack = () => {
        this.setState(prevState => {
            const newHistory = [...prevState.navigationHistory];
            newHistory.pop();
            const lastItem = newHistory[newHistory.length - 1];

            const baseState = {
                ...prevState,
                navigationHistory: newHistory,
                breadcrumbs: prevState.breadcrumbs.slice(0, -1),
                error: null,
                isLoading: false
            };

            if (!lastItem) {
                return {
                    ...baseState,
                    selectedCategory: null,
                    selectedTileType: null,
                    selectedPreference: null,
                    breadcrumbs: [],
                    navigationHistory: [],
                    memories: []
                };
            }

            if (lastItem.type === 'category') {
                return {
                    ...baseState,
                    selectedCategory: lastItem.id,
                    selectedTileType: null,
                    selectedPreference: null
                };
            }

            if (lastItem.type === 'tiletype') {
                return {
                    ...baseState,
                    selectedTileType: lastItem.id,
                    selectedPreference: null
                };
            }

            return {
                ...baseState,
                selectedPreference: lastItem.id
            };
        }, scrollToTop);
    };

    handleBreadcrumbClick = (index: number) => {
        // If clicking "Home" (index is -1), reset everything
        if (index === -1) {
            this.setState({
                selectedCategory: null,
                selectedTileType: null,
                selectedPreference: null,
                breadcrumbs: [],
                navigationHistory: [],
                isLoading: false,
                error: null
            });
            return;
        }

        // Otherwise, handle normal breadcrumb navigation
        this.setState(prevState => {
            const newHistory = prevState.navigationHistory.slice(0, index + 1);
            const newBreadcrumbs = prevState.breadcrumbs.slice(0, index + 1);
            const lastItem = newHistory[newHistory.length - 1];

            return {
                ...prevState,
                navigationHistory: newHistory,
                breadcrumbs: newBreadcrumbs,
                selectedCategory: lastItem?.type === 'category' ? lastItem.id : prevState.selectedCategory,
                selectedTileType: lastItem?.type === 'tiletype' ? lastItem.id : null,
                selectedPreference: lastItem?.type === 'preference' ? lastItem.id : null,
                isLoading: false,
                error: null
            };
        }, scrollToTop);
    };


        getCurrentTileTypes = () => {
            const category = sampleData.categories.find(
                c => c.id === this.state.selectedCategory
            );

            if (category?.type === 'Memories') {
                const memories = this.props.AtlasStore?.memories || [];

                const categoryMap = new Map<string, {
                    id: string;
                    name: string;
                    icon: string;
                    count: number;
                }>();

                memories.forEach(memory => {
                    memory.categories?.forEach(category => {
                        const rawCategory = category.replace('Category.', '');
                        if (!categoryMap.has(rawCategory)) {
                            const icon = (() => {
                                switch (rawCategory) {
                                    case 'Travel':
                                        return '✈️';
                                    case 'Social':
                                        return '👥';
                                    case 'Personal':
                                        return '👤';
                                    case 'Flights':
                                        return '🛩️';
                                    case 'Dining':
                                        return '🍽️';
                                    case 'Lodging':
                                        return '🏨';
                                    default:
                                        return 'X';
                                }
                            })();

                            categoryMap.set(rawCategory, {
                                id: rawCategory,
                                name: rawCategory,
                                icon,
                                count: 0
                            });
                        }
                        categoryMap.get(rawCategory)!.count++;
                    });
                });

                return Array.from(categoryMap.values())
                    .sort((a, b) => b.count - a.count)
                    .map(item => ({
                        id: item.id,
                        name: `${item.name}`,
                        icon: item.icon,
                        count: item.count
                    }));
            }

            if (category?.type) {
                const cachedData = this.state.atlasCache[category.type]?.data;
                if (cachedData) {
                    const tileTypes = new Map();
                    cachedData.preferences.forEach(pref => {
                        const tileType = determineTileType(pref.name);
                        if (!tileTypes.has(tileType)) {
                            tileTypes.set(tileType, {
                                id: tileType,
                                name: getTileTypeName(tileType),
                                icon: getTileTypeIcon(tileType),
                                count: 0
                            });
                        }
                        tileTypes.get(tileType).count++;
                    });
                    return Array.from(tileTypes.values());
                }
            }

            return category?.tileTypes || [];
        };


    getCurrentPreferences = (): PreferenceItem[] => {
        const selectedCategory = sampleData.categories.find(
            c => c.id === this.state.selectedCategory
        );

        if (selectedCategory?.type === 'Dossier') {
            return [{
                id: 'dossier-analysis',
                categoryId: '6',
                categoryType: 'Dossier',
                tileType: 'analysis',
                name: 'Personal Analysis Report',
                value: 'Click to view your full personal analysis',
                metadata: {
                    source: 'Analysis Engine',
                    updated_at: new Date().toISOString()
                }
            }];
        }

        if (selectedCategory?.type === 'Memories') {
            const memories = this.props.AtlasStore?.memories || [];
            const transformedMemories = memories
                .filter(memory => 
                    !this.state.selectedTileType || 
                    memory.categories?.some(category => {
                        const cleanCategory = category.replace('Category.', '');
                        return cleanCategory === this.state.selectedTileType;
                    })
                )
                .map(memory => ({
                    id: memory.id,
                    categoryId: '5',
                    categoryType: 'Memories',
                    tileType: memory.categories?.[0]?.replace('Category.', '') || 'UNCATEGORIZED',
                    name: memory.content.substring(0, 50) + (memory.content.length > 50 ? '...' : ''),
                    value: memory.content,
                    metadata: {
                        created_at: new Date(memory.created_at).toLocaleString(),
                        last_referenced: memory.last_referenced ? 
                            new Date(memory.last_referenced).toLocaleString() : undefined,
                        updated_at: memory.updated_at ? 
                            new Date(memory.updated_at).toLocaleString() : undefined,
                        categories: memory.categories?.map(c => c.replace('Category.', '')),
                        source: 'Memory Bank'
                    }
                }));
            return transformedMemories;
        }

    if (selectedCategory?.type) {
        const cachedData = this.state.atlasCache[selectedCategory.type]?.data;
        if (cachedData) {
            return this.transformAtlasData(cachedData).filter(
                pref => pref.tileType === this.state.selectedTileType
            );
        }
    }

    return sampleData.preferences.filter(p =>
        p.categoryType === selectedCategory?.type &&
        p.tileType === this.state.selectedTileType
    );
};

    transformAtlasData = (data: AtlasCategoryData) => {
        return data.preferences.map((pref, index) => ({
            id: `atlas-${data.category}-${index}`,
            categoryId: '1',
            categoryType: data.category,
            tileType: determineTileType(pref.name),
            name: pref.name,
            value: pref.value,
            metadata: {
                ...pref.metadata,
                confidence: pref.confidence,
                lastUpdated: pref.lastUpdated,
                source: pref.source
            }
        }));
    };


    private debouncedUpdatePreference = debounce(async (
        category: string,
        preferenceId: string,
        value: any
    ) => {
        try {
            await this.props.AtlasStore.updateAtlasPreference(
                category,
                preferenceId,
                value
            );
            toast.success('Preference updated successfully');
        } catch (error) {
            toast.error('Failed to update preference');
        }
    }, 500);

    render() {
        var selectedCategoryType;
        const {
            selectedCategory,
            selectedTileType,
            selectedPreference,
            isLoading,
            isLoadingPreferences,
            error
        } = this.state;

        if (selectedCategory === "1") {
            selectedCategoryType = 'Flights';
        } else if (selectedCategory === "2") {
            selectedCategoryType = 'Dining';
        } else if (selectedCategory === "3") {
            selectedCategoryType = 'Lodging';
        } else if (selectedCategory === "4") {
            selectedCategoryType = 'Purchases';
        } else if (selectedCategory === "5") {
            selectedCategoryType = 'Memories';
        }

        const isMemoryCategory = selectedCategory === '5';
        const isLoadingMemories = isMemoryCategory && this.props.AtlasStore.isLoadingMemories;
        const memoryError = isMemoryCategory ? this.props.AtlasStore.memoriesError : null;

        if (error || memoryError) {
            return (
                <ContentContainer>
                    {this.renderBreadcrumbs()}
                    {this.renderError(error || memoryError)}
                </ContentContainer>
            );
        }

        return (
            <AuthenticatedPage>
                <BasePage>
                    <ContentContainer>
                        {this.renderBreadcrumbs()}

                        {this.state.breadcrumbs.length > 0 && (
                            <BackButton
                                onClick={this.handleBack}
                                whileHover={{ x: -4 }}
                                whileTap={{ scale: 0.98 }}
                            >
                                Back to {this.state.breadcrumbs[this.state.breadcrumbs.length - 2] || 'Categories'}
                            </BackButton>
                        )}

                        <AnimatePresence mode="wait">
                            {(isLoading || isLoadingMemories || isLoadingPreferences) ? (
                              selectedCategory === '6' ? (
                                <DossierLoader />
                              ) : (
                                <AtlasLoader />
                              )
                            ) : (
                                <Section
                                    key={`view-${selectedCategory}-${selectedTileType}-${selectedPreference}`}
                                    {...pageTransition}
                                    className='Adapter-Atlas-Index'
                                >
                                    {!selectedCategory && (
                                        <CategoryList
                                            categories={sampleData.categories}
                                            onCategorySelect={this.handleCategorySelect}
                                            maxColumns={4}
                                            minTileSize={300}
                                            selectedCategory={selectedCategory}
                                        />
                                    )}

                                    {selectedCategory && !selectedTileType && (
                                        <TileTypeList
                                            categoryType={selectedCategory === '5' ? 'Memories' : selectedCategoryType}
                                            tileTypes={this.getCurrentTileTypes()}
                                            atlasData={Object.keys(this.state.atlasCache).reduce((acc, key) => ({
                                                ...acc,
                                                [key]: {
                                                    category: key,
                                                    preferences: this.state.atlasCache[key]?.data?.preferences || [],
                                                    suggestedText: this.state.atlasCache[key]?.data?.suggestedText || ''
                                                }
                                            }), {})}
                                            onTileTypeSelect={this.handleTileTypeSelect}
                                        />
                                    )}


                                    {selectedCategory && selectedTileType && !selectedPreference && (
                                        <PreferenceList
                                            preferences={this.getCurrentPreferences()}
                                            onPreferenceSelect={this.handlePreferenceSelect}
                                            maxColumns={4}
                                            minTileSize={300}
                                        />
                                    )}

                                    {selectedPreference && (
                                        selectedPreference === 'dossier-analysis' ? (
                                            <DossierView 
                                                analysis={this.state.analysisData} 
                                                onRefresh={async () => {
                                                    try {
                                                        this.setState({ isLoading: true });
                                                        const response = await API.get('/api/prompt/analysis?force_update=false');
                                                        const data = await response.json();
                                                        const parsedData = this.parseAnalysisText(data);
                                                        this.setState({ 
                                                            analysisData: parsedData,
                                                            isLoading: false 
                                                        });
                                                        toast.success('Analysis refreshed successfully');
                                                    } catch (error) {
                                                        console.error('Failed to refresh analysis:', error);
                                                        toast.error('Failed to refresh analysis');
                                                        this.setState({ isLoading: false });
                                                    }
                                                }}
                                            />
                                        ) : (
                                            <PreferenceDetail
                                                preference={this.getCurrentPreferences().find(
                                                    p => p.id === selectedPreference
                                                )!}
                                                history={this.getPreferenceHistory(selectedPreference)}
                                                understanding={this.getPreferenceUnderstanding(selectedPreference)}
                                                onBack={this.handleBack}
                                            />
                                        )
                                    )}

                                </Section>
                            )}
                        </AnimatePresence>
                    </ContentContainer>
                </BasePage>
            </AuthenticatedPage>
        );
    }

    private renderBreadcrumbs = () => (
        <BreadcrumbNav>
            <BreadcrumbItem onClick={() => this.handleBreadcrumbClick(-1)}>
                Home
            </BreadcrumbItem>
            {this.state.breadcrumbs.map((crumb, index) => (
                <React.Fragment key={index}>
                    <BreadcrumbSeparator>›</BreadcrumbSeparator>
                    <BreadcrumbItem
                        onClick={() => this.handleBreadcrumbClick(index)}
                        isActive={index === this.state.breadcrumbs.length - 1}
                    >
                        {crumb}
                        {this.renderBreadcrumbMeta(index)}
                    </BreadcrumbItem>
                </React.Fragment>
            ))}
        </BreadcrumbNav>
    );

    private renderBreadcrumbMeta = (index: number) => {
        const { selectedCategory } = this.state;
        if (index === 0 && selectedCategory) {
            const category = sampleData.categories.find(c => c.id === selectedCategory);
            if (category?.type === 'Travel' || category?.type === 'Dining') {
                const lastUpdated = this.state.lastUpdated[category?.type];
                if (lastUpdated) {
                    return (
                        <span style={{ fontSize: '12px', color: '#666', marginLeft: '8px' }}>
                            (Updated {new Date(lastUpdated).toLocaleTimeString()})
                        </span>
                    );
                }
            }
        }
        return null;
    };

    private renderLoading = () => (
          <AnimatePresence mode="wait">
            <Section 
              isLoading={true}
              key="loading" 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {(this.state.isLoading || this.state.isLoadingMemories || this.state.isLoadingPreferences) && (
                <AtlasLoader />
              )}
              {this.state.retryCount > 0 && (
                <div style={{
                  position: 'fixed',
                  bottom: '2rem',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  color: '#666',
                  fontSize: '14px',
                  textAlign: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  padding: '0.5rem 1rem',
                  borderRadius: '4px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}>
                  Retrying... (Attempt {this.state.retryCount} of {MAX_RETRY_ATTEMPTS})
                </div>
              )}
            </Section>
          </AnimatePresence>
        );



    private renderError = (error: string) => (
        <Section key="error" {...pageTransition}>
            <div style={{
                padding: 24,
                color: 'red',
                display: 'flex',
                flexDirection: 'column',
                gap: '12px'
            }}>
                <div>{error}</div>
                <button
                    onClick={this.handleRetry}
                    style={{
                        padding: '8px 16px',
                        border: 'none',
                        borderRadius: '4px',
                        background: '#f0f0f0',
                        cursor: 'pointer'
                    }}
                >
                    Retry
                </button>
            </div>
        </Section>
    );

    private getPreferenceHistory = (preferenceId: string) => {
        const selectedCategory = sampleData.categories.find(
            c => c.id === this.state.selectedCategory
        );

        if (selectedCategory?.type === 'Memories') {
            const memory = this.props.AtlasStore.memories?.find(m => m.id === preferenceId);
            if (memory) {
                return [{
                    provider: 'Memory Bank',
                    date: memory.created_at,
                    lastReferenced: memory.last_referenced,
                    updated: memory.updated_at
                }];
            }
            return [];
        }

        if (preferenceId.startsWith('atlas-')) {
            const preference = this.getCurrentPreferences().find(p => p.id === preferenceId);
            return [{
                provider: 'Atlas Service',
                date: preference?.metadata?.lastUpdated || new Date().toISOString(),
                confidence: preference?.metadata?.confidence
            }];
        }
        return [];
    };

    private getPreferenceUnderstanding = (preferenceId: string): UnderstandingItem[] => {
        const selectedCategory = sampleData.categories.find(
            c => c.id === this.state.selectedCategory
        );

        if (selectedCategory?.type === 'Memories') {
            const memory = this.props.AtlasStore.memories?.find(m => m.id === preferenceId);
            if (memory) {
                return [
                    {
                        title: 'Categories',
                        value: memory.categories?.map(c => c.replace('Category.', '')).join(', ') || 'Uncategorized',
                        type: "text"
                    },
                    {
                        title: 'Created',
                        value: new Date(memory.created_at).toLocaleString(),
                        type: "text"
                    },
                    {
                        title: 'Last Referenced',
                        value: memory.last_referenced ? 
                            new Date(memory.last_referenced).toLocaleString() : 'Never',
                        type: "text"
                    }
                ];
            }
            return [];
        }

        if (preferenceId.startsWith('atlas-')) {
            const preference = this.getCurrentPreferences().find(p => p.id === preferenceId);
            return [
                {
                    title: 'Source',
                    value: preference?.metadata?.source || 'Atlas Inference',
                    type: "text"
                },
                {
                    title: 'Confidence',
                    value: preference?.metadata?.confidence || 0,
                    type: "percentage"
                }
            ];
        }
        return [];
    };


    private handlePreferenceUpdate = async (preferenceId: string, value: any) => {
        if (preferenceId.startsWith('atlas-')) {
            const preference = this.getCurrentPreferences().find(p => p.id === preferenceId);
            if (preference) {
                await this.debouncedUpdatePreference(
                    preference.categoryType,
                    preferenceId,
                    value
                );
            }
        }
    };

    private handleRetry = () => {
        const { selectedCategory } = this.state;
        if (selectedCategory) {
            const category = sampleData.categories.find(c => c.id === selectedCategory);
            if (category?.type === 'Travel' || category?.type === 'Dining') {
                this.fetchAtlasData(category?.type);
            }
        }
    };
}

export default inject("AtlasStore")(observer(AtlasPage));