import React from 'react';

const External = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path
            d="M21.5 9L21.5 3M21.5 3H15.5M21.5 3L13.5 11M10.5 5H8.3C6.61984 5 5.77976 5 5.13803 5.32698C4.57354 5.6146 4.1146 6.07354 3.82698 6.63803C3.5 7.27976 3.5 8.11984 3.5 9.8V16.2C3.5 17.8802 3.5 18.7202 3.82698 19.362C4.1146 19.9265 4.57354 20.3854 5.13803 20.673C5.77976 21 6.61984 21 8.3 21H14.7C16.3802 21 17.2202 21 17.862 20.673C18.4265 20.3854 18.8854 19.9265 19.173 19.362C19.5 18.7202 19.5 17.8802 19.5 16.2V14" 
            strokeWidth="2"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default External;
