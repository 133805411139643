import React from 'react';

const Search = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="193" height="193" viewBox="0 0 193 193" fill="none">
        <path
            fill={color}
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M114.093 46.9222C95.3483 28.177 64.9564 28.177 46.2112 46.9222C27.4661 65.6673 27.4661 96.0592 46.2112 114.804C64.9564 133.55 95.3483 133.55 114.093 114.804C132.839 96.0592 132.839 65.6673 114.093 46.9222ZM34.8975 35.6084C59.8911 10.6149 100.414 10.6149 125.407 35.6084C148.485 58.6861 150.254 95.0033 130.714 120.111L165.809 155.206C168.933 158.33 168.933 163.395 165.809 166.52C162.685 169.644 157.62 169.644 154.495 166.52L119.401 131.425C94.2931 150.965 57.9754 149.196 34.8975 126.118C9.90395 101.125 9.90396 60.602 34.8975 35.6084ZM44.1523 40.8626C44.1523 43.0718 42.3615 44.8626 40.1523 44.8626C37.9432 44.8626 36.1523 43.0718 36.1523 40.8626C36.1523 38.6535 37.9432 36.8626 40.1523 36.8626C42.3615 36.8626 44.1523 38.6535 44.1523 40.8626ZM164.152 160.863C164.152 163.072 162.361 164.863 160.152 164.863C157.943 164.863 156.152 163.072 156.152 160.863C156.152 158.654 157.943 156.863 160.152 156.863C162.361 156.863 164.152 158.654 164.152 160.863ZM120.152 36.8626C117.943 36.8626 116.152 38.6535 116.152 40.8626C116.152 43.0718 117.943 44.8626 120.152 44.8626C122.361 44.8626 124.152 43.0718 124.152 40.8626C124.152 38.6535 122.361 36.8626 120.152 36.8626ZM36.1523 120.863C36.1523 118.653 37.9432 116.863 40.1523 116.863C42.3615 116.863 44.1523 118.653 44.1523 120.863C44.1523 123.072 42.3615 124.863 40.1523 124.863C37.9432 124.863 36.1523 123.072 36.1523 120.863Z" 
        />
    </svg>
);

export default Search;