import { ReactNode } from 'react';
import './Tour.scss';

interface Props {
    children?: ReactNode;
}

const Tour = ({children}: Props) => {
    return (
      <div 
        className='adapter-tour' 
        data-posthog-id="adapter-welcome-tour"
        style={{ 
          minHeight: '1px',
          minWidth: '1px',
          position: 'relative',
          display: 'block'
        }}
      >
        {children}
      </div>
    );
  };
export default Tour;