import API from "util/API";

export class AnthropicService {
  async sendMessage(prompt: string): Promise<any> {
    const response = await API.post('/api/llm/messages', {
      content: prompt,
    });

    const data = await response.json();

    // Handle the response content properly
    const content = data.content[0].type === 'text'
      ? data.content[0].text
      : JSON.stringify(data.content[0]);

    return {
      id: data.id,
      content,
      model: data.model,
      role: data.role,
      usage: {
        input_tokens: data.usage.input_tokens,
        output_tokens: data.usage.output_tokens,
      },
    };
  }
}

export const anthropicService = new AnthropicService();
export default AnthropicService;