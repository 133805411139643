import React from "react";
import { inject, observer } from "mobx-react";

import AuthenticatedPage from "components/common/AuthenticatedPage";
import BasePage from 'components/common/BasePage';
import AdapterChat from "components/common/Chat/AdapterChat";
import withRouter from "hooks/withRouter";
import { Job } from "models/Job";
import { Text } from "components/common/Typography";

import "../../brand/index.scss";

interface JobByIdProps {
    ActionLogStore?: any;
    PromptStore?: any;
    WebSocketStore?: any;
    router?: any;
}

interface JobByIdState {
    pollCount: number;
}

class JobByIdPage extends React.Component<JobByIdProps, JobByIdState> {
    state = {
        pollCount: 0
    };

    componentDidMount(): void {
        this.setState({ pollCount: 0 }, this.pollForJobUpdate);
    }

    pollForJobUpdate = () => {
        const { ActionLogStore, PromptStore, router } = this.props;
        const { pollCount } = this.state;

        if (pollCount === 3) {
            // add message to the UI so the user knows it isn't broken
            PromptStore.addSystemMessage(
                <Text>
                    Adapter will continue to search for results. Feel free to click away from this screen, 
                    you can check the job list any time for a status update.
                </Text>
            );
        }

        this.setState({ pollCount: pollCount + 1 }, () => {
            ActionLogStore.getJob(router.params.jobId)
                .then((job: Job) => {
                    const { job_status, type } = job;

                    if (
                        type !== 'unsupported' &&
                        (job_status === 'pending' || job_status === 'in_progress')
                    ) {
                        // check for updates every 5 seconds
                        setTimeout(this.pollForJobUpdate, 5_000);
                    } else {
                        PromptStore.removeSystemMessage();
                        this.setState({ pollCount: 0 });
                    }
                });
        });
    }

    render() {
        return (
            <AuthenticatedPage>
                <BasePage>
                    <AdapterChat />
                </BasePage>
            </AuthenticatedPage>
        );
    }
}

export default withRouter(inject("ActionLogStore", "PromptStore", "WebSocketStore")(observer(JobByIdPage)));
