import React from "react";
import { inject, observer } from "mobx-react";
import { toast } from "react-toastify";
import Button from "components/common/Button";
import { HeadText, Text } from "components/common/Typography";
import "../../brand/index.scss";
import { SettingsLayout } from "./SettingsLayout";
import Loading from "components/common/Loading";

interface ResetPasswordPageProps {
  AuthStore?: any;
  UserStore?: any;
}

interface ResetPasswordState {
  hasPassword: boolean;
  socialProviders: string[];
}

const PROVIDER_DISPLAY_NAMES: Record<string, string> = {
  'google-oauth2': 'Google',
  'github': 'GitHub',
  'apple': 'Apple',
  'facebook': 'Facebook',
  'linkedin': 'LinkedIn',
  'twitter': 'X',
  'windowsLive': 'Microsoft'
};

class ResetPasswordPage extends React.Component<ResetPasswordPageProps, ResetPasswordState> {
  state = {
    hasPassword: null,
    socialProviders: []
  };

  getProviderDisplayName = (provider: string): string => {
    return PROVIDER_DISPLAY_NAMES[provider.toLowerCase()] || provider;
  };

  componentDidMount = async () => {
    try {
      const idpData = await this.props.UserStore.getIdpData();
      const socialProviders = idpData
        .filter(entry => entry.isSocial)
        .map(entry => this.getProviderDisplayName(entry.provider));

      const hasPassword = idpData.some(entry =>
        entry.connection === "Username-Password-Authentication" &&
        !entry.isSocial
      );

      this.setState({ hasPassword, socialProviders });
    } catch (error) {
      toast.error('An error occurred');
    }
  }

  clickHandler = async () => {
    try {
      await this.props.AuthStore.resetPassword();
      toast.success('Please check your email');
    } catch (error) {
      toast.error('An error occurred');
    }
  }
  renderLoading = () => (
    <div className="adapter-card">
      <HeadText fontSize="xs" cls='adapter-font-color-primary-900' fontWeight='bold'>
        Gathering Account Information
      </HeadText>
      <Loading/>
    </div>
  );

  renderPasswordReset = () => (
    <div className="adapter-card">
      <HeadText fontSize="xs" cls='adapter-font-color-primary-900' fontWeight='bold'>
        Reset Password
      </HeadText>
      <Text>
        To reset your password, click the button below.
      </Text>
      <Text>
        We&apos;ll send you an email with instructions to securely reset your Adapter password.
      </Text>
      <Button
        onClick={this.clickHandler}
        size="2xl"
      >
        Send me an email &rarr;
      </Button>
    </div>
  )

  renderExternalAuth = () => (
    <div className="adapter-card">
      <HeadText fontSize="xs" cls='adapter-font-color-primary-900' fontWeight='bold'>
        Password Management
      </HeadText>
      <Text>
        Your identity is managed by the following providers:
      </Text>
      {this.state.socialProviders.map((provider, index) => (
        <Text key={index}>• {provider}</Text>
      ))}
      <Text>
        Your credentials can be updated by managing those accounts directly.
      </Text>
    </div>
  )

  render() {
    return (
      <SettingsLayout>
        <div style={{ width: '80vw', maxWidth: '600px', alignSelf: 'center' }}>
          {this.state.hasPassword == null
            ? this.renderLoading() : 
            this.state.hasPassword ?
              this.renderPasswordReset() : this.renderExternalAuth()}
        </div>
        <div className="flex-1"></div>
      </SettingsLayout>
    );
  }
}

export default inject("AuthStore", "UserStore")(observer(ResetPasswordPage));