import { Text } from 'components/common/Typography';
import { SearchIcon } from 'components/common/Icon';

import './index.scss';

const EmptyState = () => {
    return (
        <div className='flex-1 flex flex-col'>
            <div className='flex-1 mt-40' />
            <div className='atlas-empty-state'>
                <SearchIcon color="#7D4EE1" />
                <Text fontSize='l'>Connect more accounts or add memories to see more preferences.</Text>
            </div>
            <div className='flex-1 mb-40' />
        </div>
    );
}

export default EmptyState;