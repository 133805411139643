import React from 'react';
import { inject, observer } from 'mobx-react';
import Notice from 'components/common/Notice';
import PromptComprehension from 'components/common/PromptComprehension';
import ErrorBoundary from 'components/common/ErrorBoundary';
import { Text } from 'components/common/Typography';

import '../Option.scss';

type InvalidParametersProps = { 
    PromptStore?: any;
    message: string;
}

const InvalidParamters = ({ PromptStore, message }: InvalidParametersProps) => {
    return (
        <>
            <Notice text='Invalid Request' warning />

            <div data-test-id="errors.invalid_params">
                Adapter is unable to process this request due to the following invalid input.
                Please edit your request using the prompt bar below.
            </div>

            <ErrorBoundary fallback={<Text>An error occurred. If you continue to see this message, please contact Adapter.</Text>}>
                <PromptComprehension comprehension={PromptStore.comprehensionResponse} />
            </ErrorBoundary>

            <div>
                {message}
            </div>
        </>
    );
};

export default inject('PromptStore')(observer(InvalidParamters));