import React from 'react';
import { usePostHog } from 'posthog-js/react';
import Tabs from "components/common/Tab";
import AuthenticatedPage from "components/common/AuthenticatedPage";
import BasePage from "components/common/BasePage";
import { useLocation } from 'react-router-dom';

interface Tab {
  title: string;
  url: string;
  active?: boolean;
}

// Updated hook to take current path
export const useTabs = (showUserKnowledge: boolean, currentPath?: string) => {
  const tabsSpread = (showUserKnowledge: boolean, path: string): Tab[] => {
    const baseTabs: Tab[] = [
      {
        title: 'Profile',
        url: '/settings/profile',
        active: path === '/settings/profile'
      },
      {
        title: 'Password',
        url: '/settings/password',
        active: path === '/settings/password'
      },
      {
        title: 'Accounts',
        url: '/settings/accounts',
        active: path === '/settings/accounts'
      },
    ];

    const userKnowledgeTab: Tab = {
      title: 'Details',
      url: '/settings/user-knowledge',
      active: path === '/settings/user-knowledge'
    };

    return [
      ...baseTabs.slice(0, 1),
      ...(showUserKnowledge ? [userKnowledgeTab] : []),
      ...baseTabs.slice(1)
    ];
  };
  return tabsSpread(showUserKnowledge, currentPath || '');
};

interface SettingsLayoutProps {
  children: React.ReactNode;
}

export const SettingsLayout: React.FC<SettingsLayoutProps> = ({ children }) => {
  const posthog = usePostHog();
  const location = useLocation();
  const tabs = useTabs(posthog?.isFeatureEnabled('user-knowledge'), location.pathname);

  return (
    <AuthenticatedPage>
      <BasePage>
        <Tabs tabs={tabs} />
        {children}
      </BasePage>
    </AuthenticatedPage>
  );
};