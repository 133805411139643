import React from 'react';

const Message = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path
            d="M7.5 8.5H12.5M7.5 12H15.5M10.1837 18H16.7C18.3802 18 19.2202 18 19.862 17.673C20.4265 17.3854 20.8854 16.9265 21.173 16.362C21.5 15.7202 21.5 14.8802 21.5 13.2V7.8C21.5 6.11984 21.5 5.27976 21.173 4.63803C20.8854 4.07354 20.4265 3.6146 19.862 3.32698C19.2202 3 18.3802 3 16.7 3H8.3C6.61984 3 5.77976 3 5.13803 3.32698C4.57354 3.6146 4.1146 4.07354 3.82698 4.63803C3.5 5.27976 3.5 6.11984 3.5 7.8V20.3355C3.5 20.8684 3.5 21.1348 3.60923 21.2716C3.70422 21.3906 3.84827 21.4599 4.00054 21.4597C4.17563 21.4595 4.38367 21.2931 4.79976 20.9602L7.18521 19.0518C7.67252 18.662 7.91617 18.4671 8.18749 18.3285C8.4282 18.2055 8.68443 18.1156 8.94921 18.0613C9.24767 18 9.5597 18 10.1837 18Z" 
            strokeWidth="2"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Message;
