import React from 'react';

const ThumbsDown = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M14.1665 1.66699V10.8337M18.3332 8.16699V4.33366C18.3332 3.40024 18.3332 2.93353 18.1516 2.57701C17.9918 2.2634 17.7368 2.00844 17.4232 1.84865C17.0667 1.66699 16.6 1.66699 15.6665 1.66699H6.76489C5.54699 1.66699 4.93804 1.66699 4.4462 1.88985C4.01271 2.08627 3.6443 2.40234 3.38425 2.80092C3.08919 3.25315 2.99659 3.85502 2.8114 5.05876L2.37551 7.8921C2.13125 9.47975 2.00912 10.2736 2.24472 10.8913C2.4515 11.4334 2.84042 11.8867 3.34482 12.1735C3.91949 12.5003 4.72266 12.5003 6.32899 12.5003H6.99988C7.46659 12.5003 7.69995 12.5003 7.87821 12.5912C8.03501 12.671 8.16249 12.7985 8.24239 12.9553C8.33321 13.1336 8.33321 13.3669 8.33321 13.8337V16.2788C8.33321 17.4137 9.25321 18.3337 10.3881 18.3337C10.6588 18.3337 10.9041 18.1742 11.014 17.9269L13.8146 11.6255C13.942 11.3389 14.0057 11.1956 14.1064 11.0905C14.1954 10.9976 14.3047 10.9266 14.4257 10.883C14.5626 10.8337 14.7194 10.8337 15.033 10.8337H15.6665C16.6 10.8337 17.0667 10.8337 17.4232 10.652C17.7368 10.4922 17.9918 10.2372 18.1516 9.92364C18.3332 9.56712 18.3332 9.10041 18.3332 8.16699Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ThumbsDown;