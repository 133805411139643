type UserMetrics = {
    timezone: string;
    tzOffset: number;
}

export const getUserMetrics = (): UserMetrics => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzOffset = new Date().getTimezoneOffset();
    
    return {
        timezone,
        tzOffset
    }
}