import React from 'react';
import { inject, observer } from 'mobx-react';
import { MartiniIcon, HotelIcon, PlaneIcon } from 'components/common/Icon';
import Intention, { IntentionLoading } from './index';
import Progress from '../Progress';

type SuggestionType = "flight" | "dining" | "lodging";

type PromptSuggestion = {
    type: SuggestionType;
    request: string;
    short_request: string;
    user_location: string;
    refs?: string[];
}

const ICONS = {
    'flight': PlaneIcon,
    'lodging': HotelIcon,
    'dining': MartiniIcon,
} as const;

class IntentionList extends React.Component<{ NLPStore?: any; PromptStore?: any }> {
    componentDidMount(): void {
 
        const { PromptStore } = this.props;
        if (!PromptStore.loadingSuggestions) {
            PromptStore.getPromptSuggestions();
        }
    }

    render() {
        const { PromptStore, NLPStore } = this.props;

        // Access the suggestions array from the response structure
        let allSuggestions: PromptSuggestion[] = [...PromptStore.promptSuggestions];

        if (PromptStore.loadingSuggestions) {
            // these will show as loading, it doesn't matter if they're empty
            // @ts-ignore
            allSuggestions.push({}, {}, {});
        } else if (!allSuggestions.length) {
            // loading complete, nothing to display
            return null;
        }
        return (
            <div className="adapter-intention-list">
                {allSuggestions.map((suggestion, i) => {
                    if (PromptStore.loadingSuggestions) {
                        return (<IntentionLoading key={i} />);
                    }

                    return (
                        <Intention
                            key={i}
                            description={suggestion.short_request}
                            prompt={suggestion.request}
                            Icon={ICONS[suggestion.type]}
                            refs={suggestion.refs ?? []}
                            store={NLPStore.isCurrentTab ? NLPStore : PromptStore}
                        />
                    );
                })}
            </div>
        );
    }
}

export default inject('NLPStore', 'PromptStore')(observer(IntentionList));