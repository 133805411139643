import React from 'react';

const Guide = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path
            d="M12.5 21L12.3999 20.8499C11.7053 19.808 11.358 19.287 10.8991 18.9098C10.4929 18.5759 10.0248 18.3254 9.52161 18.1726C8.95325 18 8.32711 18 7.07482 18H5.7C4.57989 18 4.01984 18 3.59202 17.782C3.21569 17.5903 2.90973 17.2843 2.71799 16.908C2.5 16.4802 2.5 15.9201 2.5 14.8V6.2C2.5 5.07989 2.5 4.51984 2.71799 4.09202C2.90973 3.71569 3.21569 3.40973 3.59202 3.21799C4.01984 3 4.57989 3 5.7 3H6.1C8.34021 3 9.46031 3 10.316 3.43597C11.0686 3.81947 11.6805 4.43139 12.064 5.18404C12.5 6.03968 12.5 7.15979 12.5 9.4M12.5 21V9.4M12.5 21L12.6001 20.8499C13.2947 19.808 13.642 19.287 14.1009 18.9098C14.5071 18.5759 14.9752 18.3254 15.4784 18.1726C16.0467 18 16.6729 18 17.9252 18H19.3C20.4201 18 20.9802 18 21.408 17.782C21.7843 17.5903 22.0903 17.2843 22.282 16.908C22.5 16.4802 22.5 15.9201 22.5 14.8V6.2C22.5 5.07989 22.5 4.51984 22.282 4.09202C22.0903 3.71569 21.7843 3.40973 21.408 3.21799C20.9802 3 20.4201 3 19.3 3H18.9C16.6598 3 15.5397 3 14.684 3.43597C13.9314 3.81947 13.3195 4.43139 12.936 5.18404C12.5 6.03968 12.5 7.15979 12.5 9.4"
            strokeWidth="2"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Guide;
