import React from "react";
import { observer, inject } from "mobx-react";
import { HeadText, Text, LINK } from "components/common/Typography";
import config from "config";

type AccessDeniedProps = {
  AuthStore?: any;
}

class AccessDeniedPage extends React.Component<AccessDeniedProps, {}> {
  handleSignout = () => {
    const { AuthStore } = this.props;

    AuthStore.logout().then(() => {
      window.location.href = '/login';
    });
  }

  render() {
    const auth0Domain = window.REACT_APP_AUTH0_DOMAIN ?? config.auth0.domain;
    return (
      <main className="adapter-basepage">
        <div className="adapter-basepage-inner">
          <div className="adapter-fullscreen-content">
            <div className="flex-1"></div>

                <HeadText fontSize="xl" fontWeight="bold">Access Denied</HeadText>

                <Text>Sorry, you don&apos;t have permission to view this page.</Text>

                <Text>If you just created an account, you must first verify your email in order to login.</Text>
                <Text>Otherwise, delete your cookies and try to login again.</Text>

                <Text>
                    Please contact <LINK href="mailto:support@adapter.com">support@adapter.com</LINK> if you continue to encounter problems.
                  </Text>

                <Text>
                  Return to the <LINK href="https://www.adapter.com">Adapter Homepage</LINK>&nbsp;
                  use <LINK href={`https://${auth0Domain}/v2/logout?returnTo=${encodeURIComponent(window.location.origin)}`}>different credentials</LINK>&nbsp;
                  or attempt to use the same ones <LINK onClick={this.handleSignout}>login</LINK>.
                </Text>

            <div className="flex-1"></div>
          </div>
        </div>
      </main>
    );
  }
}

export default inject('AuthStore')(observer(AccessDeniedPage));