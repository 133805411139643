// /frontend/src/pages/atlas/PreferenceList.tsx

import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Preference, Category } from './types';
import MemoryAnalysis from 'components/MemoryAnalysis';

import { addSpacesToTitleCase } from 'util/Text';

// Grid container with responsive columns and dynamic row heights
const MasonryGrid = styled.div`
  column-count: 3; // Default for largest screens
  column-gap: 24px;
  padding: 24px;
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;

  @media (max-width: 1400px) {
    column-count: 3; // Reduce to 3 columns for large screens
  }

  @media (max-width: 1200px) {
    column-count: 2; // Reduce to 2 columns for medium screens
  }

  @media (max-width: 768px) {
    column-count: 1; // Single column for mobile
    padding: 16px;
  }
`;

const MasonryItem = styled(motion.div)`
  break-inside: avoid;
  margin-bottom: 24px;
  display: inline-block;
  width: 100%;
`;

// Update the Card component to remove the aspect-ratio
const Card = styled(motion.div)<{ isMemory?: boolean }>`
  background: white;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  min-height: ${props => props.isMemory ? '200px' : 'auto'};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  }
`;

// Header section containing type information
const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
`;

// Wrapper for type labels
const TypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

// Category/Type label styling
const PreferenceLabel = styled.div`
  color: #727271;
  font-family: "Rand Light", sans-serif;
  font-size: 12px;
  text-align: left;
  font-weight: 570;
  letter-spacing: 0.2px;
  text-transform: capitalize;
`;

// Main type label styling
const TypeLabel = styled.div`
  color: #171717;
  font-family: "Rand Medium", sans-serif;
  font-size: 18px;
  font-weight: 570;
  letter-spacing: 0.2px;
  text-align: left;
`;

// Content wrapper for preference details
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

// Preference name styling
const Name = styled.h2`
  font-size: 24px;
  font-weight: 300;
  font-family: "Rand Medium", sans-serif;
  margin: 0;
  color: #0F0F0F;
  line-height: 32px;
  text-align: left;
  width: 100%;
`;

// Location information styling
const LocationInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #666;
  font-size: 12px;
  font-family: "Rand Light", sans-serif;
  font-weight: 300;
  letter-spacing: 0.2px;
  margin-top: auto;
`;

// Location item styling
const LocationItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const MemoryDate = styled.span`
  font-size: 12px;
  color: #666;
  font-family: "Rand Light", sans-serif;
`;

const MemoryContent = styled.p`
  margin: 0;
  font-size: 14px;
  color: #444;
  line-height: 1.5;
  flex-grow: 1;
  font-family: "Rand Light", sans-serif;
`;

const MemoryMetadata = styled.div`
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #eee;
  font-size: 12px;
  color: #666;
  display: flex;
  justify-content: space-between;
  font-family: "Rand Light", sans-serif;
`;

const ConfidenceIndicator = styled.div<{ confidence: number }>`
  display: flex;
  align-items: center;
  gap: 4px;
  
  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => {
      if (props.confidence >= 0.8) return '#4CAF50';
      if (props.confidence >= 0.5) return '#FFC107';
      return '#F44336';
    }};
  }
`;

// Animation variants for cards
const cardVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
  hover: { scale: 1.02 }
};

// Component props interface
interface PreferenceListProps {
  preferences: (Preference | MemoryItem)[];
  selectedCategory?: Category;
  onPreferenceSelect: (id: string) => void;
  maxColumns?: number;
  minTileSize?: number;
}


interface MemoryItem extends Preference {
  metadata?: {
    date?: string;
    source?: string;
    confidence?: number;
    isMemory?: boolean;
    [key: string]: any;
  };
}

interface AnalysisCache {
  [key: string]: {
    analysis: any;
    timestamp: number;
    expiresAt: number;
  };
}

export const PreferenceList: React.FC<PreferenceListProps> = ({
  preferences,
  selectedCategory,
  onPreferenceSelect,
  maxColumns = 4,
  minTileSize = 250
}) => {
  const [columns, setColumns] = useState(maxColumns);
  const containerRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<Map<string, HTMLDivElement>>(new Map());

  // Filter preferences based on selected category
  const filteredPreferences = selectedCategory
    ? preferences.filter(pref => pref.categoryId === selectedCategory.id)
    : preferences;

  // Calculate optimal number of columns based on container width
  const calculateColumns = useCallback(() => {
    if (!containerRef.current) return;
    
    const containerWidth = containerRef.current.offsetWidth;
    const gap = 24;
    
    const maxPossibleColumns = Math.floor((containerWidth + gap) / (minTileSize + gap));
    setColumns(Math.max(1, Math.min(maxColumns, maxPossibleColumns)));
  }, [maxColumns, minTileSize]);

  // Update grid item heights
  const updateItemHeights = useCallback(() => {
    requestAnimationFrame(() => {
      itemsRef.current.forEach((item) => {
        const height = item.getBoundingClientRect().height;
        item.style.gridRowEnd = `span ${Math.ceil(height + 24)}`;
      });
    });
  }, []);

  // Initialize ResizeObserver for responsive layout
  useEffect(() => {
    calculateColumns();
    const resizeObserver = new ResizeObserver(() => {
      calculateColumns();
      updateItemHeights();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [calculateColumns, updateItemHeights]);

  // Add window resize listener with debounce
  useEffect(() => {
    const debouncedResize = debounce(() => {
      calculateColumns();
      updateItemHeights();
    }, 100);

    window.addEventListener('resize', debouncedResize);
    return () => window.removeEventListener('resize', debouncedResize);
  }, [calculateColumns, updateItemHeights]);

  const renderPreferenceCard = (preference: Preference | MemoryItem) => {
    const isMemory = 'metadata' in preference && preference.metadata?.isMemory;

    if (isMemory) {
      return (
        <Card 
          isMemory={true}
          onClick={() => onPreferenceSelect(preference.id)}
          variants={cardVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          whileHover="hover"
          transition={{ duration: 0.2 }}
        >
          <Header>
            <TypeWrapper>
              <PreferenceLabel>Memory</PreferenceLabel>
              <TypeLabel>{preference.name}</TypeLabel>
            </TypeWrapper>
            {preference.metadata?.date && (
              <MemoryDate>
                {new Date(preference.metadata.date).toLocaleDateString()}
              </MemoryDate>
            )}
          </Header>
          <ContentWrapper>
            <MemoryContent>{preference.value}</MemoryContent>
            <MemoryMetadata>
              <span>Source: {preference.metadata?.source || 'Unknown'}</span>
              {preference.metadata?.confidence && (
                <ConfidenceIndicator confidence={preference.metadata.confidence}>
                  {Math.round(preference.metadata.confidence * 100)}% confidence
                </ConfidenceIndicator>
              )}
            </MemoryMetadata>
          </ContentWrapper>
        </Card>
      );
    }

    return (
      <Card 
        onClick={() => onPreferenceSelect(preference.id)}
        variants={cardVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        whileHover="hover"
        transition={{ duration: 0.2 }}
        className='Adapter-PreferenceList-1'
      >
        <Header>
          <TypeWrapper>
            <PreferenceLabel>
              {selectedCategory?.type || preference.categoryType}
            </PreferenceLabel>
            <TypeLabel>{addSpacesToTitleCase(preference.name)}</TypeLabel>
          </TypeWrapper>
        </Header>
        <ContentWrapper>
          <Name>{preference.value}</Name>
          <LocationInfo>
            {preference.location?.code && (
              <LocationItem>
                <span>Code:</span>
                <span>{preference.location.code}</span>
              </LocationItem>
            )}
            {preference.location?.city && (
              <LocationItem>
                <span>Location:</span>
                <span>{preference.location.city}</span>
              </LocationItem>
            )}
            {preference.location?.all && (
              <LocationItem>{preference.location.all}</LocationItem>
            )}
          </LocationInfo>
        </ContentWrapper>
      </Card>
    );
  };

  // Empty state handling
  if (filteredPreferences.length === 0) {
    return (
      <div ref={containerRef} style={{ width: '100%', padding: '24px' }}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          style={{
            textAlign: 'center',
            color: '#666',
            padding: '48px',
            background: 'white',
            borderRadius: '16px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
          }}
        >
          <h3 style={{ margin: '0 0 8px', color: '#333' }}>No items found</h3>
          <p style={{ margin: 0 }}>
            {selectedCategory 
              ? `No ${selectedCategory.type.toLowerCase()} items available.`
              : 'No items available.'}
          </p>
        </motion.div>
      </div>
    );
  }

  return (
    <div ref={containerRef} style={{ width: '100%' }} className='Adapter-PreferenceList-2'>
      <MasonryGrid>
        {filteredPreferences.map((preference, index) => (
          <MasonryItem
            key={preference.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3, delay: index * 0.05 }}
          >
            {renderPreferenceCard(preference)}
          </MasonryItem>
        ))}
      </MasonryGrid>
    </div>
  );
};

// Utility function for debouncing
const debounce = (fn: Function, ms: number) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export default PreferenceList;