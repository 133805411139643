import React from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';

import { Text } from '../Typography';
import { ResponseBase } from 'models/comprehension/response/base';
import { ThumbsUpIcon, ThumbsDownIcon } from '../Icon';

import { EVENTS } from 'pages/support';

type PromptFeedbackProps = {
    comprehension: ResponseBase;
    PromptStore?: any;
}

type PromptFeedbackState = {
    votedUp: boolean;
    votedDown: boolean;
}

class PromptFeedback extends React.Component<PromptFeedbackProps, PromptFeedbackState> {
    state = {
        votedUp: false,
        votedDown: false
    };
    
    sendPromptFeedback = (response_id = '', feedback = true, notes = '') => {
        const { PromptStore } = this.props;

        PromptStore.sendPromptFeedback(response_id, feedback, notes)
            .then(() => {
                if (feedback) {
                    toast.success(
                        "Thank you for your feedback!",
                        { theme: 'dark', position: 'bottom-right' }
                    );
                }
            })
            .catch(() => {
                toast.error(
                    "An error occurred. If you continue to see this problem, please contact Adapter.",
                    { theme: 'dark', position: 'bottom-right' }
                );
            });
    }

    voteUp = () => {
        const { comprehension } = this.props;

        // @ts-ignore
        const id = comprehension?.uuid;
        
        this.sendPromptFeedback(id, true);
        this.setState({ votedDown: false, votedUp: true });
    }

    voteDown = () => {
        const { comprehension } = this.props;

        // @ts-ignore
        const id = comprehension?.uuid;
        
        this.sendPromptFeedback(id, false);
        this.setState({ votedUp: false, votedDown: true }, () => {
            // @ts-ignore
            ADAPTER.publishEvent(EVENTS.openFeedback);
        });
    }

    render() {
        const { comprehension } = this.props;
        const { votedUp, votedDown } = this.state;

        // @ts-ignore
        const id = comprehension?.uuid;

        // we only have this UUID when the user initially prompts (and is waiting for agent response)
        // historical "jobs" won't have this anymore, so don't try to display it
        if (!id) {
            return <></>;
        }

        let style = {};

        return (
            <div className='comprehension-feedback'>
                <Text>
                    Was this understanding correct?
                </Text>

                <ThumbsUpIcon onClick={this.voteUp} style={style} color={votedUp ? '#7549F2' : ''} />
                <ThumbsDownIcon onClick={this.voteDown} style={style} color={votedDown ? '#7549F2' : ''} />
            </div>
        );
    }
}

export default inject("PromptStore")(observer(PromptFeedback));