import React from 'react';

import HotelOption from './HotelOption';
import MapView from 'pages/intentions/MapView';
import { Text } from 'components/common/Typography';

const HotelResponse = ({ results }) => {
    const options = [];

    if (Array.isArray(results)) {
        for (let i = 0; i < results.length; i++) {
            options.push(
                <HotelOption 
                    key={results[i].index}
                    option={results[i].option}
                    index={results[i].index}
                    score={results[i].option.score}
                    allScores={results.map(r => r.option.score)}
                />
            );
        }
    }

    if (options.length === 0) {
        return (
            <>
                <p>
                    Adapter could not find any results that match your request.
                    Please try adjusting your your request to find more options.
                </p>

                <p>
                    Helpful Tips:
                </p>

                <ul>
                    <li>- Expand the time window of your search</li>
                </ul>
            </>
        );
    }

    return (
        <>
            <Text>
                Adapter found {options.length} option(s) and ranked them based on your needs and preferences:
            </Text>

            {/* <MapView options={results} type="hotel" /> */}

            {options}
        </>
    );
};

export default HotelResponse;