import React from 'react';
import { observer, inject } from 'mobx-react';
import { toast } from "react-toastify";

import withRouter from 'hooks/withRouter';
import { SendIcon } from '../../../components/common/Icon';
import InputWithIconButton from '../../../components/forms/fields/InputWithIconButton';
import { ComprehensionResponse } from 'models/comprehension';

import './InputBar.scss';

type InputBarProps = {
    placeholder: string;
    PromptStore?: any;
    ActionLogStore?: any;
    router?: any;
    style?: any;
    cls?: string;
};

class InputBar extends React.Component<InputBarProps, any> {
    state = {
        loading: false
    }

    promptResponse = () => {
        const { router, PromptStore } = this.props;
        const data: ComprehensionResponse = PromptStore.comprehensionResponse;
        this.setState({ loading: false });
        const jobId = data.trigger_event_id || data.job_id;

        // redirect the page if necessary
        if (jobId) {
            // navigate without reloading the page, if we can
            router.navigate(`/intention/${jobId}`);
        }
    }

    promptError = () => {
        this.setState({ loading: false });
    }

    onParseInput = () => {
        const { ActionLogStore, PromptStore, router } = this.props;

        this.setState({ loading: true }, () => {
            router.navigate(`/dashboard`);

            PromptStore.parseInput()
                .then(this.promptResponse)
                .then(() => ActionLogStore.listJobs())
                .catch(this.promptError);
        });
    }

    render() {
        const { placeholder, PromptStore, style = {}, cls = '' } = this.props;
        const { loading } = this.state;

        return (
            <>
                <div className={`intention-inputbar ${cls}`} style={style}>
                    <InputWithIconButton
                        fieldId=""
                        dataTestId="agent-service-prompt"
                        placeholder={placeholder}
                        onChange={(e: any) => PromptStore.setRawPrompt(e.target.value)}
                        Icon={SendIcon}
                        onClick={this.onParseInput}
                        onEnterKey={this.onParseInput}
                        style={{ flex: 1 }}
                        value={PromptStore.rawPrompt}
                        buttonConfig={{
                            title: 'Let\'s go!',
                            color: PromptStore.rawPrompt === null ? '#98A2B3' : '#7549F2',
                            disabled: loading || PromptStore.rawPrompt === null
                        }}
                    />

                </div>
            </>
        );
    }
}

export default withRouter(inject("ActionLogStore", "PromptStore")(observer(InputBar)));