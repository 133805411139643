// /frontend/src/constants/CategoryMappings.ts
export interface CategoryMapping {
    keywords: string[];
    displayName: string;
    icon: string;
}

export interface CategoryMappings {
    [key: string]: CategoryMapping;
}

export const CATEGORY_MAPPINGS: CategoryMappings = {
    airline: {
        keywords: [
            'airline', 'carrier', 'flight', 'alliance', 'aircraft',
            'plane', 'aviation', 'airways', 'skyteam', 'oneworld',
            'star alliance', 'boarding', 'terminal', 'gate',
            'layover', 'connection', 'nonstop', 'direct',
            'domestic', 'international', 'transoceanic',
            'charter', 'codeshare', 'segment'
        ],
        displayName: 'Airlines',
        icon: '✈️'
    },
    spend: {
        keywords: [
            'budget', 'cost', 'price', 'fare', 'spend', 'rate',
            'expense', 'payment', 'charge', 'fee', 'pricing',
            'value', 'deposit', 'refund',
            'prepaid', 'package', 'deal', 'promotion', 'discount',
            'surge', 'peak', 'off-peak', 'seasonal rate',
            'nightly rate', 'per night', 'per diem', 'invoice',
            'transaction', 'billing', 'installment', 'subscription'
        ],
        displayName: 'Budget',
        icon: '💰'
    },
    timing: {
        keywords: [
            'schedule', 'departure', 'arrival', 'timing', 'pattern', 'time',
            'breakfast', 'lunch', 'brunch', 'dinner', 'drinks',
            'weekday', 'weekend', 'reservation', 'frequency',
            'check-in', 'check-out', 'duration', 'length of stay',
            'morning', 'afternoon', 'evening', 'night',
            'early', 'late', 'dawn', 'dusk', 'midnight',
            'hourly', 'daily', 'weekly', 'monthly', 'quarterly',
            'seasonal', 'holiday', 'peak season', 'off-season',
            'calendar', 'itinerary', 'availability', 'booking window',
            'advance notice', 'cancellation period', 'flexibility',
            'punctuality', 'delay', 'extension', 'overtime'
        ],
        displayName: 'Time & Schedule',
        icon: '⏰'
    },
    comfort: {
        keywords: [
            'seat', 'comfort', 'cabin', 'row', 'window',
            'aisle', 'middle', 'exit row', 'bulkhead',
            'recline', 'legroom', 'headroom', 'armrest',
            'temperature', 'climate', 'ventilation', 'air quality',
            'noise level', 'soundproof', 'quiet', 'peaceful',
            'spacious', 'cramped', 'cozy', 'ergonomic',
            'cushion', 'padding', 'support', 'adjustable',
            'accessible', 'mobility', 'assistance', 'wheelchair',
            'storage', 'overhead bin', 'under-seat'
        ],
        displayName: 'Comfort',
        icon: '💺'
    },
    cuisine: {
        keywords: [
            'cuisine', 'food', 'dining',
            'menu', 'dish', 'meal', 'appetizer', 'entree', 'dessert',
            'beverage', 'wine', 'cocktail', 'beer', 'spirit', 'dietary',
            'vegetarian', 'vegan', 'pescatarian', 'halal', 'kosher',
            'organic', 'gluten-free', 'dairy-free', 'nut-free',
            'spicy', 'mild', 'savory', 'sweet', 'umami',
            'fusion', 'traditional', 'modern', 'experimental',
            'michelin', 'gourmet', 'casual', 'fast-casual',
            'takeout', 'delivery', 'catering', 'buffet'
        ],
        displayName: 'Cuisines',
        icon: '🍳'
    },
    location: {
        keywords: [
            'location', 'address', 'destination', 'city', 'country', 'route', 'airport',
            'region', 'continent', 'hemisphere', 'timezone', 'geographic',
            'metropolitan', 'suburban', 'rural', 'urban',
            'waterfront', 'mountainside', 'lakefront', 'riverside', 'restaurant',
            'coastline', 'island', 'peninsula', 'mainland',
            'north', 'south', 'east', 'west', 'central',
            'district', 'quarter', 'zone', 'precinct',
            'landmark', 'attraction', 'site', 'venue',
            'coordinates', 'latitude', 'longitude', 'elevation',
            'proximity', 'radius', 'perimeter', 'boundary'
        ],
        displayName: 'Locations',
        icon: '📍'
    },
    loyalty: {
        keywords: [
            'rewards', 'status', 'membership',
            'elite', 'tier', 'points', 'benefits',
            'perks', 'privileges', 'recognition', 'qualification',
            'milestone', 'achievement', 'anniversary', 'lifetime',
            'platinum', 'gold', 'silver', 'bronze',
            'premier', 'priority', 'exclusive', 'vip',
            'club', 'insider',
            'accumulation', 'redemption', 'transfer', 'conversion',
            'rollover', 'expiration', 'retention', 'upgrade'
        ],
        displayName: 'Loyalty Programs',
        icon: '🎯'
    },
    accommodation: {
        keywords: [
            'hotel', 'resort', 'lodging', 'accommodation', 'amenities',
            'property', 'vacation rental', 'boutique', 'requirement', 'method', 'platform', 'requests',
            'inn', 'villa', 'apartment', 'condo', 'cottage',
            'chalet', 'bungalow', 'mansion', 'penthouse',
            'hostel', 'motel', 'lodge', 'retreat',
            'residence', 'homestay', 'guesthouse', 'airbnb',
            'timeshare', 'all-inclusive', 'bed-breakfast',
            'camping', 'glamping', 'caravan', 'yacht',
            'room', 'suite', 'studio', 'apartment'
        ],
        displayName: 'Accommodation',
        icon: '🏨'
    },
    services: {
        keywords: [
            'concierge', 'valet', 'housekeeping', 'maintenance',
            'reception', 'front-desk', 'bellhop', 'porter',
            'shuttle', 'transfer', 'chauffeur', 'transportation',
            'laundry', 'dry-cleaning', 'pressing', 'tailoring',
            'spa', 'massage', 'wellness', 'fitness',
            'pool', 'jacuzzi', 'sauna', 'steam-room',
            'business-center', 'conference', 'meeting-room',
            'internet', 'wifi', 'broadband', 'connectivity',
            'security', 'surveillance', 'safe-deposit',
            'childcare', 'babysitting', 'pet-care', 'medical'
        ],
        displayName: 'Services',
        icon: '🛎️'
    },
    social: {
        keywords: [
            'party', 'group', 'gathering', 'meeting',
            'celebration', 'event', 'function', 'occasion',
            'solo', 'couple', 'family', 'friends', 'corporate', 'team', 'delegation',
            'conference', 'convention', 'symposium', 'seminar',
            'wedding', 'anniversary', 'birthday', 'reunion',
            'private', 'public', 'exclusive', 'open',
            'networking', 'socializing', 'entertaining', 'hosting',
            'capacity', 'attendance', 'headcount', 'occupancy'
        ],
        displayName: 'Social & Events',
        icon: '👥'
    },
    merchants: {
        keywords: [
            'amazon', 'walmart', 'target', 'costco', 'best buy',
            'store', 'shop', 'retailer', 'merchant', 'vendor',
            'marketplace', 'outlet', 'mall', 'shopping center',
            'supermarket', 'grocery store', 'department store',
            'online store', 'e-commerce', 'retail chain',
            'boutique', 'shop', 'seller', 'supplier'
        ],
        displayName: 'Top Merchants',
        icon: '🏪'
    },
    categories: {
        keywords: [
            'electronics', 'groceries', 'clothing', 'furniture',
            'home goods', 'appliances', 'books', 'music',
            'sporting goods', 'toys', 'games', 'beauty',
            'health', 'wellness', 'automotive', 'garden',
            'office supplies', 'pet supplies', 'jewelry',
            'digital goods', 'software', 'streaming', 'entertainment'
        ],
        displayName: 'Shopping Categories',
        icon: '📋'
    },
    spending: {
        keywords: [
            'purchase', 'transaction', 'order', 'cart',
            'checkout', 'payment', 'receipt', 'invoice',
            'total', 'subtotal', 'tax', 'shipping',
            'refund', 'return', 'exchange', 'credit',
            'debit', 'installment', 'financing', 'discount', 'categor',
            'coupon', 'promo', 'sale', 'clearance'
        ],
        displayName: 'Spending Patterns',
        icon: '💰'
    },
    frequency: {
        keywords: [
            'frequent', 'regular', 'recurring', 'repeat',
            'weekly', 'monthly', 'quarterly', 'annual',
            'daily', 'occasional', 'one-time', 'subscription',
            'auto-delivery', 'auto-ship', 'scheduled',
            'periodic', 'routine', 'pattern', 'cycle'
        ],
        displayName: 'Purchase Frequency',
        icon: '📊'
    },
    subscriptions: {
        keywords: [
            'subscription', 'recurring', 'monthly', 'annual',
            'streaming', 'membership', 'service', 'plan',
            'auto-renewal', 'billing cycle', 'term', 'period',
            'netflix', 'spotify', 'prime', 'disney+',
            'subscription box', 'digital service', 'membership fee'
        ],
        displayName: 'Subscriptions',
        icon: '🔄'
    },
    seasonal: {
        keywords: [
            'seasonal', 'holiday', 'christmas', 'halloween',
            'black friday', 'cyber monday', 'prime day',
            'summer sale', 'winter sale', 'spring',
            'fall', 'back to school', 'clearance',
            'end of season', 'promotional period'
        ],
        displayName: 'Seasonal Trends',
        icon: '📅'
    },
    digital: {
        keywords: [
            'digital', 'download', 'streaming', 'online',
            'electronic delivery', 'virtual', 'e-book',
            'software', 'app', 'subscription service',
            'digital content', 'cloud storage', 'web service',
            'digital product', 'electronic goods'
        ],
        displayName: 'Digital vs Physical',
        icon: '💻'
    }
};

export function determineTileType(preferenceName: string): string {
    const lowerName = preferenceName.toLowerCase();
    
    for (const [type, mapping] of Object.entries(CATEGORY_MAPPINGS)) {
        if (mapping.keywords.some(keyword => lowerName.includes(keyword))) {
            return type;
        }
    }
    
    return 'Other';
}

export function getTileTypeName(type: string): string {
    return CATEGORY_MAPPINGS[type]?.displayName || type;
}

export function getTileTypeIcon(type: string): string {
    return CATEGORY_MAPPINGS[type]?.icon || '📋';
}

export function filterPreferencesByTileType(preferences: any[], tileType: string): any[] {
    return preferences.filter(pref => {
        const prefName = pref.name.toLowerCase();
        return CATEGORY_MAPPINGS[tileType]?.keywords.some(
            keyword => prefName.includes(keyword)
        ) || false;
    });
}
