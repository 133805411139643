// /frontend/src/pages/atlas/CategoryList.tsx
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Category } from './types';
import { motion } from 'framer-motion'; // Add animation support
import { addSpacesToTitleCase } from 'util/Text';

// Grid container with responsive columns
const Grid = styled.div<{ $columns: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.$columns}, 1fr);
  gap: 24px;
  padding: 24px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

// Animated card component with hover effects
const Card = styled(motion.div)`
  background: white;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0.05));
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover::after {
    opacity: 1;
  }
`;

// Header section with flex layout
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  position: relative;
  z-index: 1;
`;

// Container for type labels
const TypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

// Category label styling
const CategoryLabel = styled.div`
  color: #727271;
  font-family: "Rand Light", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: color 0.2s ease;

  ${Card}:hover & {
    color: #5B5B5B;
  }
`;

// Collection label styling
const CollectionLabel = styled.div`
  color: #171717;
  font-family: "Rand Medium", sans-serif;
  font-size: 16px;
  letter-spacing: 0.2px;
  transition: color 0.2s ease;

  ${Card}:hover & {
    color: #000;
  }
`;

// Circular container for category icons
const IconCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #F4F3FF;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;

  ${Card}:hover & {
    background-color: #E6E3FF;
    transform: scale(1.05);
  }
`;

// Category icon component
const CategoryIcon = styled.div<{ $icon: string }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: transform 0.2s ease;
  
  &::before {
    content: '${props => props.$icon}';
  }

  ${Card}:hover & {
    transform: scale(1.1);
  }
`;
// Main content wrapper
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  position: relative;
  z-index: 1;
`;

// Category name styling
const Name = styled.h2`
  font-size: 24px;
  font-family: "Rand Medium", sans-serif;
  margin: 0;
  color: #0F0F0F;
  line-height: 1.2;
  transition: color 0.2s ease;

  ${Card}:hover & {
    color: #000;
  }
`;

// Description text styling
const Description = styled.div`
  color: #666;
  font-size: 14px;
  font-family: "Rand Light", sans-serif;
  line-height: 1.4;
  transition: color 0.2s ease;
  
  ${Card}:hover & {
    color: #444;
  }
`;

// Container for tile type preview tags
const TileTypesPreview = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: auto;
  padding-top: 16px;
`;

// Individual tile type tag styling
const TileTypeTag = styled.div`
  background-color: #F4F3FF;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  color: #4A4A4A;
  font-family: "Rand Light", sans-serif;
  transition: all 0.2s ease;

  ${Card}:hover & {
    background-color: #E6E3FF;
    color: #333;
  }
`;

// Component props interface
interface CategoryListProps {
  categories: Category[];
  onCategorySelect: (id: string) => void;
  maxColumns?: number;
  minTileSize?: number;
  selectedCategory?: string | null;
}

// Animation variants for cards
const cardVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
  hover: { scale: 1.02 }
};

export const CategoryList: React.FC<CategoryListProps> = ({
  categories,
  onCategorySelect,
  maxColumns = 4,
  minTileSize = 300,
  selectedCategory
}) => {
  // State for managing responsive columns
  const [columns, setColumns] = useState(maxColumns);
  const containerRef = useRef<HTMLDivElement>(null);

  // Calculate optimal number of columns based on container width
  const calculateColumns = () => {
    if (!containerRef.current) return;
    
    const containerWidth = containerRef.current.offsetWidth;
    const gap = 24;
    
    const maxPossibleColumns = Math.floor((containerWidth + gap) / (minTileSize + gap));
    setColumns(Math.max(1, Math.min(maxColumns, maxPossibleColumns)));
  };

  // Initialize ResizeObserver for responsive layout
  useEffect(() => {
    calculateColumns();
    const resizeObserver = new ResizeObserver(calculateColumns);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => resizeObserver.disconnect();
  }, [maxColumns, minTileSize]);

  // Add window resize listener with debounce
  useEffect(() => {
    const debouncedCalculateColumns = debounce(calculateColumns, 100);
    window.addEventListener('resize', debouncedCalculateColumns);
    return () => window.removeEventListener('resize', debouncedCalculateColumns);
  }, []);

  // Handle category selection
  const handleCategoryClick = (category: Category) => {
    onCategorySelect(category.id);
    document.querySelector('.adapter-basepage-inner').scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <div ref={containerRef} style={{ width: '100%' }} className='Adapter-CategoryList'>
      <Grid $columns={columns}>
        {categories.map((category, index) => (
          <Card 
            key={category.id} 
            onClick={() => handleCategoryClick(category)}
            variants={cardVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            whileHover="hover"
            transition={{ duration: 0.2, delay: index * 0.05 }}
            style={{
              opacity: selectedCategory && selectedCategory !== category.id ? 0.6 : 1,
              transform: selectedCategory === category.id ? 'scale(1.02)' : 'none'
            }}
          >
            <Header>
              <TypeWrapper>
                <CategoryLabel>{category.type}</CategoryLabel>
                <CollectionLabel>Collection</CollectionLabel>
              </TypeWrapper>
              <IconCircle>
                <CategoryIcon $icon={category.icon} />
              </IconCircle>
            </Header>
            <ContentWrapper>
              <Name>{addSpacesToTitleCase(category.name)}</Name>
              <Description>{category.description}</Description>
              <TileTypesPreview>
                {category.tileTypes.slice(0, 3).map(tileType => (
                  <TileTypeTag key={tileType.id}>
                    {(tileType.name.length > 12) ? `${tileType.name.substring(0, 10)}...` : tileType.name}
                  </TileTypeTag>
                ))}
                {category.tileTypes.length > 3 && (
                  <TileTypeTag>+{category.tileTypes.length - 3} more</TileTypeTag>
                )}
              </TileTypesPreview>
            </ContentWrapper>
          </Card>
        ))}
      </Grid>
    </div>
  );
};

// Utility function for debouncing
const debounce = (fn: Function, ms: number) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};
