import { ComprehensionResponse } from "..";
import Constraints from "../constraints";
import { ResponseBase, ComprehensionView, LiteralType } from "./base";

interface Memory {
    content: string;
    categories?: string[];
}

export class MemoryResponse extends ResponseBase {
    action: LiteralType<"create" | "read" | "update" | "delete">;
    response: string;
    memories?: Memory[];

    constructor(data: any, comprehensionResponse?: ComprehensionResponse) {
        super(comprehensionResponse);
        if (data.summary) {
            Object.assign(this, data.summary);
        } else {
            Object.assign(this, data);
        }
    }

    render(constraints?: Constraints): ComprehensionView {
        const attrs: { [key: string]: string[] } = {};
        
        this.memories?.forEach(memory => {
            attrs[memory.content] = memory.categories || [];
        });

        return {
            text: this.response,
            attrs
        };
    }
}