import React from 'react';
import './Progress.scss';

interface ProgressProps {
    progress?: number;
    progress_status_message?: string;
  }
  
  const Progress: React.FC<ProgressProps> = ({ progress = 100, progress_status_message = '' }) => (
    <div className="flex flex-col gap-2">
      <div className="relative w-[135px] bg-gray-200 rounded-lg">
        <div className="adapter-progress origin-left" style={{transform: `scaleX(${progress/100})`}} />
      </div>
      <div className="text-sm text-gray-600">{progress_status_message}</div>
    </div>
   );

export default Progress;