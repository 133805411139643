import { APPLICATION_BUILD_NUMBER } from "../constants";

type CallbackFn = (value: any) => void;

class Adapter {
    public eventBus: { [key: string]: CallbackFn[]} = null;

    public version = {
        build: APPLICATION_BUILD_NUMBER
    };

    constructor() {
        this.eventBus = {};

        console.log(`
                                                 ..                                                 
                                                ....                                                
                                               ..  ..                                               
                                              ..    ..                                              
                                            .....  .....                                            
                                           ...  ....  ...                                           
                                          ...   ....   ...                                          
                                         ........  ........                                         
                                        ...  ....  ....  ...                                        
                                       ...  ..  ....  ..  ...                                       
                                      .......  ......  .......                                      
                                     ..  ..................  ..                                     
                                    ............................                                    
                                   ..............................                                   
                                  .. ......              ...... ..                                  
                                 .......                    .......                                 
                                ......                        ......                                
                               .....                            .....                               
                              ....                                ....                              
                             ...                                    ...                             
                            ...                                      ...                            
                            ..                                        ..                            
                                                                                                    
        ....                   ....                                                                 
       ......                  ....                                  ....                           
      ........                 ....                                  ....                           
      ........          .....  ....       ...             ....       ....       ......           ...
     ....  ....       .............   ...........   ............. .......... ...........   .........
    ....    ....    ....... .......  .....   .....  ....... ...... ............... ......  .........
   .....    .....   ....       ....  ...     .....  ....       ....  ....  ....       .... .....    
   ............... ....        ....   ............  ....       ....  ....  ............... ....     
  ................ ....        ....  ........ ....  ....       ....  ....  ............... ....     
 .....         .... ....      ..... ....      ....  ....       ....  ....  .....       ... ....     
.....          .................... ..............  ..............   ....   .............. ....     
....            ....  .............  .............  ............      .....  ...........   ....     
                                                    ....                                            
                                                    ....                                            
                                                    ...                                             
        `);
    }

    createNamespace = (namespace: string, value: any = {}) => {
        // Split the namespace string into an array of keys
        const keys = namespace.split('.');

        // Start with the global window object
        let currentObject = window;

        // Iterate over the keys, creating nested objects as needed
        keys.forEach((key, index) => {
            if (!currentObject[key]) {
                currentObject[key] = {};
            }

            // If we're at the last key, assign the value
            if (index === keys.length - 1) {
                currentObject[key] = value;
            } else {
                currentObject = currentObject[key];
            }
        });
    }

    publishEvent = (eventName: string, value: any) => {
        // Get all the callback functions of the current event
        const callbackList = this.eventBus[eventName];

        if (!callbackList) {
            return console.warn(eventName + " not found!");
        }

        // execute each callback function
        for (let callback of callbackList) {
            callback(value);
        }
    }

    subscribeToEvent = (eventName, callback: CallbackFn) => {
        if (!this.eventBus[eventName]) {
            this.eventBus[eventName] = [];
        }

        this.eventBus[eventName].push(callback);
    }
};

// @ts-ignore
window.ADAPTER = new Adapter();

// @ts-ignore
export default window.ADAPTER;