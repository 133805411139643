import React, { ChangeEvent, FormEvent } from "react";
import { inject, observer } from "mobx-react";
import { toast } from "react-toastify";

import AuthenticatedPage from "components/common/AuthenticatedPage";
import BasePage from "components/common/BasePage";
import BaseForm from "components/forms/BaseForm";
import Tabs from "components/common/Tab";
import Card from "components/common/Card";
import { HeadText, Text } from "components/common/Typography";
import { validateInputs } from "components/forms/fields/DateInput";
import withPostHog from "hooks/withPosthog";

import "../../brand/index.scss";
import { SettingsLayout } from "./SettingsLayout";

interface ProfilePageProps {
    UserStore?: any;
    userEvents?: any;
}

interface ProfilePageState {
    first_name: string | undefined;
    last_name: string | undefined;
    phone: string | undefined;
    email: string | undefined;
    homeAddress: string | undefined;
    dateOfBirth: string | undefined;
}

class ProfilePage extends React.Component<ProfilePageProps, ProfilePageState> {
    state = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        homeAddress: '',
        dateOfBirth: ''
    };

    componentDidMount(): void {
        const { UserStore } = this.props;

        UserStore
            .loadUser()
            .then((user) => {
                this.setState({
                    first_name: user.first_name || '',
                    last_name: user.last_name || '',
                    phone: user.phone || '',
                    email: user.email || '',
                    homeAddress: user.metadata.homeAddress || '',
                    dateOfBirth: user.metadata.dateOfBirth || ''
                });
            })
    }

    onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        // @ts-ignore
        this.setState({ [name]: value });
    };

    onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        const { UserStore } = this.props;
        const { email, first_name, last_name, phone, homeAddress, dateOfBirth } = this.state;

        event.preventDefault();

        UserStore.updateUser({
            // @ts-ignore
            email: email.toLowerCase(),
            first_name,
            last_name,
            phone,
            metadata: { homeAddress, dateOfBirth }
        })
            .catch(() => {
                toast.error("Oops! Something went wrong, please try again.", { icon: false });
            });
    };

    render() {
        const { UserStore, userEvents } = this.props;
        const { email, phone, first_name, last_name, homeAddress, dateOfBirth } = this.state;

        const dobErrors = validateInputs(dateOfBirth);
        const hasDobError = dobErrors.MM.error || dobErrors.DD.error || dobErrors.YYYY.error;

        const config = [
            {
                labelText: "Email Address",
                fieldId: "email",
                type: "email",
                value: email,
                disabled: true,
            },
            {
                labelText: "First Name",
                fieldId: "first_name",
                type: "text",
                value: first_name,
                required: true,
                errorText: first_name.length === 0 ? "First name is required" : undefined
            },
            {
                labelText: "Last Name",
                fieldId: "last_name",
                type: "text",
                value: last_name,
                required: true,
                errorText: last_name.length === 0 ? "Last name is required" : undefined
            },
            {
                labelText: "Phone",
                fieldId: "phone",
                type: "text",
                value: phone,
                required: true,
                errorText: phone.length === 0 ? "Phone number is required" : undefined
            },
            {
                labelText: "Home Address",
                fieldId: "homeAddress",
                type: "address",
                value: homeAddress,
                required: true,
                errorText: homeAddress.length === 0 ? "Home address is required" : undefined
            },
            {
                labelText: "Birthday",
                fieldId: "dateOfBirth",
                type: "date",
                value: dateOfBirth,
                required: true,
                errorText: hasDobError ? "DOB is required" : undefined
            },
        ];

        return (
            <SettingsLayout>
                <div style={{ width: '80vw', maxWidth: '600px', alignSelf: 'center' }}>
                    <HeadText fontSize="xs" cls='adapter-font-color-primary-900' fontWeight='bold'>
                        Personal Info
                    </HeadText>

                    <Text>Update your personal details here.</Text>

                    <Card>
                        <BaseForm
                            config={config}
                            isLoading={UserStore.isLoading}
                            buttonText="Save Changes"
                            onChange={this.onChangeHandler}
                            onSubmit={this.onSubmitHandler}
                            style={{ minWidth: '50%' }}
                        />
                    </Card>
                </div>

                <div className="flex-1"></div>
            </SettingsLayout>
        );
    }
}

export default withPostHog(inject("UserStore")(observer(ProfilePage)));
