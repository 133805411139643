import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
    filterPreferencesByTileType,
    getTileTypeName,
    getTileTypeIcon
} from '../../constants/CategoryMappings';
import EmptyState from './EmptyState';
import { addSpacesToTitleCase } from 'util/Text';

// Interfaces
interface AtlasPreference {
    name: string;
    value: any;
    metadata?: {
        confidence?: number;
        lastUpdated?: string;
        source?: string;
        [key: string]: any;
    };
}

interface TileType {
    id: string;
    name: string;
    icon: string;
    count?: number;
    atlasKey?: string;
}

interface TileTypeListProps {
    categoryType: string;
    tileTypes: TileType[];
    atlasData: Record<string, {
        category: string;
        preferences: AtlasPreference[];
        suggestedText: string;
    }>;
    onTileTypeSelect: (type: string) => void;
}

interface PreferenceValueProps {
    value: string;
    maxLines?: number;
}

// Styled Components
const TileTypeGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
    padding: 24px;
    width: 100%;
`;

const TileTypeCard = styled(motion.div)`
    background: white;
    border-radius: 16px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    transition: transform 0.2s ease, box-shadow 0.2s ease;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
`;

const TypeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const CategoryLabel = styled.div`
    color: #727271;
    font-family: "Rand Light";
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.2px;
`;

const TileTypeName = styled.div`
    color: #171717;
    font-family: "Rand Medium";
    font-size: 20px;
    font-weight: 570;
    letter-spacing: 0.2px;
`;

const IconCircle = styled.div`
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: #F4F3FF;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TileTypeIcon = styled.div`
    font-size: 20px;
`;

const PreferencesGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    margin-top: auto;
    padding-top: 16px;
    overflow: hidden;
`;

const PreferenceItem = styled.div`
    background: #F4F3FF;
    border-radius: 12px;
    padding: 12px;
    min-height: 52px;
    min-width: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
`;

const PreferenceName = styled.div`
    color: #666666;
    font-family: "Rand Light";
    font-size: 11px;
    line-height: 13px;
    text-transform: capitalize;
    margin-bottom: 2px;
`;

const PreferenceValue = styled.div<{ needsGradient?: boolean }>`
    color: #333333;
    font-family: "Rand Medium";
    font-size: 13px;
    line-height: 16px;
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 16px;
        width: 100%;
        background: linear-gradient(
            to top,
            rgba(244, 243, 255, 0.95) 0%,
            rgba(244, 243, 255, 0) 100%
        );
        pointer-events: none;
        opacity: ${props => props.needsGradient ? 1 : 0};
    }
`;

const EmptyPreferences = styled.div`
    color: #999;
    font-size: 12px;
    font-family: "Rand Light";
    font-style: italic;
    text-align: center;
    padding: 12px;
    grid-column: 1 / -1;
`;

const PreferenceValueComponent: React.FC<PreferenceValueProps> = ({ value, maxLines = 2 }) => {
    const valueRef = useRef<HTMLDivElement>(null);
    const [needsGradient, setNeedsGradient] = useState(false);

    useEffect(() => {
        if (valueRef.current) {
            const element = valueRef.current;
            setNeedsGradient(
                element.scrollHeight > element.clientHeight
            );
        }
    }, [value]);

    return (
        <PreferenceValue
            ref={valueRef}
            needsGradient={needsGradient}
            style={{
                WebkitLineClamp: maxLines
            }}
        >
            {value}
        </PreferenceValue>
    );
};

const formatPreferenceValue = (value: any): string => {
    if (Array.isArray(value)) {
        return value.join(', ');
    }

    if (typeof value === 'object' && value !== null) {
        return Object.values(value).join(' to ');
    }

    return String(value);
};

export const TileTypeList: React.FC<TileTypeListProps> = ({
    categoryType,
    tileTypes,
    atlasData,
    onTileTypeSelect
}) => {
    const getPreferencesForTileType = (tileType: TileType): AtlasPreference[] => {
        if (categoryType === 'Memories') {
            return []; // For memories, we don't use preferences
        }
        const preferences = atlasData[categoryType]?.preferences || [];
        return filterPreferencesByTileType(preferences, tileType.id);
    };

if (tileTypes.length === 0) {
    return (<EmptyState />);
}

const renderTileContent = (tileType: TileType) => {
        if (categoryType === 'Memories') {
            // For memories, just show the count
            return (
                <PreferencesGrid>
                    <PreferenceItem>
                        <PreferenceName>Total Memories</PreferenceName>
                        <PreferenceValueComponent 
                            value={`${tileType.count || 0} memories`}
                            maxLines={1}
                        />
                    </PreferenceItem>
                </PreferencesGrid>
            );
        }

        // For other categories, show preferences
        const tilePreferences = getPreferencesForTileType(tileType);
        return (
            <PreferencesGrid>
                {tilePreferences.length > 0 ? (
                    tilePreferences.slice(0, 4).map((preference, index) => (
                        <PreferenceItem key={`${tileType.id}-${index}`}>
                            <PreferenceName>{addSpacesToTitleCase(preference.name)}</PreferenceName>
                            <PreferenceValueComponent 
                                value={formatPreferenceValue(preference.value.split("[")[0])}
                                maxLines={2}
                            />
                        </PreferenceItem>
                    ))
                ) : (

                    <EmptyPreferences>No preferences set</EmptyPreferences>
                )}
            </PreferencesGrid>
        );
    };

    return (
        <TileTypeGrid className='Adapter-TileTypeGrid'>
            {tileTypes.map(tileType => {
                const displayName = categoryType === 'Memories' ? 
                    tileType.name : 
                    getTileTypeName(tileType.id);
                const icon = categoryType === 'Memories' ? 
                    tileType.icon : 
                    getTileTypeIcon(tileType.id);

                return (
                    <TileTypeCard
                        key={tileType.id}
                        onClick={() => onTileTypeSelect(tileType.id)}
                        whileHover={{ scale: 1.02 }}
                        transition={{ duration: 0.2 }}
                        className='Adapter-TileTypeCard'
                    >
                        <Header>
                            <TypeWrapper>
                                <TileTypeName>
                                    {displayName}
                                </TileTypeName>
                            </TypeWrapper>
                            <IconCircle>
                                <TileTypeIcon>{icon}</TileTypeIcon>
                            </IconCircle>
                        </Header>


                        {renderTileContent(tileType)}
                        
                    </TileTypeCard>
                );
            })}
        </TileTypeGrid>
    );
};

export default TileTypeList;