import React from 'react';
import humanizeDuration from 'humanize-duration';
import { toJS } from 'mobx';
import { formatDate_ShortHuman, formatTime_AmPm } from 'util/Time';
import Button from 'components/common/Button';
import { PlaneIcon } from 'components/common/Icon';
import Pill from 'components/common/Pill';
import MatchComparisonBar from '../bar/MatchComparisonBar';

import './FlightOption.scss';
import { HeadText, Text } from 'components/common/Typography';

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3
});

const formatPrice = (price: number | undefined): string => {
    return price ? USDollar.format(price) : 'Price not available';
};

export type OneWayFlight = {
    airline_logo: string[];
    airlines: string[];
    airplanes: string[];
    arrival_airport: string;
    arrival_time: string;
    booking_link: string;
    booking_url?: string;
    booking_urls?: string[];
    departure_airport: string;
    departure_time: string;
    durations: number[];
    total_duration: number;
    flight_numbers: string[];
    layovers: any[];
    legroom: string[];
    overnight: boolean[];
    price: number;
    travel_class: string[];
    ranking_reasoning?: string;
    utility_costs?: UtilityCosts;
};

export type FlightLayover = {
    airport_name: string;
    airport_code: string;
    duration_minutes: number;
}

export type FlightLegOption = {
    arrival_airport_name?: string;
    arrival_time: string;
    arrival_date: string;
    arrival_airport: string;
    departure_date: string;
    airplane?: string;
    flight_number: string;
    price?: number;
    travel_class:string;
    duration_minutes: number;
    airline: string;
    airlines?: string[];
    flight_numbers?: string[];
    layovers?: FlightLayover[];
    departure_airport_name:string;
    departure_airport: string;
    departure_time: string;
};

export type RoundTripFlight = {
    price?: number;
    booking_url?: string;
    booking_urls?: string[];
    departure_option: OneWayFlight;
    return_option: OneWayFlight;
    utility_costs?: UtilityCosts;
    ranking_reasoning?: string;
};

export type MultiCityFlight = {
    price: number;
    booking_url?: string;
    booking_urls?: string[];
    legs: FlightLegOption[];
    ranking_reasoning?: string;
};

export type UtilityCosts = {
    time_cost_before: number;
    time_cost_after: number;
    stops_preference_cost: number;
    total_costs: number;
    time_cost: number;
};

type FlightOptionProps = {
    option: OneWayFlight | RoundTripFlight | MultiCityFlight;
    index: number;
    allOptions: (OneWayFlight | RoundTripFlight | MultiCityFlight)[];
    ranking_reasoning?: string;
    score?: number;
    allScores?: number[];
};

class FlightOption extends React.Component<FlightOptionProps, {}> {
    isRoundTrip = () => {
        const { option } = this.props;
        return 'departure_option' in option;
    }

    isMultiCity = () => {
        const { option } = this.props;
        return 'legs' in option;
    }

    getFlightType = () => {
        if (this.isRoundTrip()) return 'round trip';
        if (this.isMultiCity()) return 'multi-city';
        return 'one way';
    }

    getPrice = () => {
        const { option } = this.props;
    
        if (this.isRoundTrip()) {
            const roundTripOption = option as RoundTripFlight;
            const price = roundTripOption.price ?? roundTripOption.return_option.price ?? roundTripOption.departure_option.price;
            return formatPrice(price);
        }
    
        return formatPrice(option.price);
    };

    sortLegs = (legs: FlightLegOption[]): FlightLegOption[] => {
        return legs.sort((a, b) => {
            const dateA = new Date(`${a.departure_date}T${a.departure_time}`);
            const dateB = new Date(`${b.departure_date}T${b.departure_time}`);
            return dateA.getTime() - dateB.getTime();
        });
    }

    renderFlightLeg = (leg: OneWayFlight | FlightLegOption, index: number, label?: string) => {
        if (!leg) return <></>;
    
        const isOneWayOrRoundTrip = 'total_duration' in leg;
    
        const departureTime = isOneWayOrRoundTrip ? leg.departure_time : leg.departure_date + 'T' + leg.departure_time;
        const arrivalTime = isOneWayOrRoundTrip ? leg.arrival_time : leg.arrival_date + 'T' + leg.arrival_time;
        const duration = isOneWayOrRoundTrip ? leg.total_duration : leg.duration_minutes;
        const airline = isOneWayOrRoundTrip 
            ? leg.airlines[0] 
            : this.getAirlinesFromLeg(leg as FlightLegOption)[0];
        const airlineLogo = isOneWayOrRoundTrip ? leg.airline_logo[0] : undefined;
    
        return (
            <div key={index} className="option-details">
                {label && <div className="text-sm font-medium text-gray-600 mb-2">{label}</div>}
                <div className="option-details-title-wrapper">
                    <div>
                        {airlineLogo
                            ? <img src={airlineLogo} alt={airline} className="airline-logo" />
                            : <PlaneIcon />
                        }
                    </div>
                    <div><Text>{formatDate_ShortHuman(departureTime)}</Text></div>
                    <div><Text>{airline}</Text></div>
                </div>
    
                <div className="option-details-body-wrapper">
                    <div>
                        <HeadText fontSize='xs'>{formatTime_AmPm(departureTime)}</HeadText>
                        <Text fontSize='s'>{isOneWayOrRoundTrip ? leg.departure_airport : leg.departure_airport_name}</Text>
                    </div>
    
                    <div className="duration">
                        <div className="visual"></div>
                        <div>
                            {`${Math.floor(duration / 60)}h ${duration % 60}m`}
                        </div>
                        <div className="layovers">
                            {isOneWayOrRoundTrip 
                                ? (leg.layovers && leg.layovers.length > 0
                                    ? `${leg.layovers.length} stop(s): ${leg.layovers.join(', ')}`
                                    : 'nonstop')
                                : ((leg as FlightLegOption).layovers?.length 
                                    ? `${(leg as FlightLegOption).layovers.length} stop(s): ${(leg as FlightLegOption).layovers.map(l => l.airport_code).join(', ')}`
                                    : 'nonstop')
                            }
                        </div>
                    </div>
    
                    <div>
                        <HeadText fontSize='xs'>{formatTime_AmPm(arrivalTime)}</HeadText>
                        <Text fontSize='s'>{isOneWayOrRoundTrip ? leg.arrival_airport : leg.arrival_airport_name}</Text>
                    </div>
                </div>
            </div>
        );
    }

    renderFlightDetails = () => {
        const { option } = this.props;

        if (this.isRoundTrip()) {
            const roundTripOption = option as RoundTripFlight;
            return (
                <>
                    {this.renderFlightLeg(roundTripOption.departure_option, 0, 'Outbound Flight')}
                    {this.renderFlightLeg(roundTripOption.return_option, 1, 'Return Flight')}
                </>
            );
        } else if (this.isMultiCity()) {
            const multiCityOption = option as MultiCityFlight;
            const sortedLegs = this.sortLegs(multiCityOption.legs);
            return sortedLegs.map((leg, index) => this.renderFlightLeg(leg, index));
        } else {
            return this.renderFlightLeg(option as OneWayFlight, 0);
        }
    }

    getRankingReason = () => {
        const { option } = this.props;
        const unwrappedOption = toJS(option);

        // First try to get it directly from the root option
        if (unwrappedOption.ranking_reasoning) {
            return unwrappedOption.ranking_reasoning;
        }

        // If not at root level, check if it's a round trip flight
        if (this.isRoundTrip()) {
            const roundTripOption = unwrappedOption as RoundTripFlight;
            return roundTripOption.ranking_reasoning;
        }

        // If multi-city, check that object
        if (this.isMultiCity()) {
            const multiCityOption = unwrappedOption as MultiCityFlight;
            return multiCityOption.ranking_reasoning;
        }

        // Finally check one-way flight
        const oneWayOption = unwrappedOption as OneWayFlight;
        return oneWayOption.ranking_reasoning;
    }

    renderButton = () => {
        const { option } = this.props;
        
        // Handle multiple booking URLs
        if (option.booking_urls && option.booking_urls.length > 0) {
            return (
                <div className='flight-option-button-wrapper'>
                    <div className='flight-option-price-display'>
                        <HeadText fontSize='s'>{this.getPrice()}</HeadText>
                        <Text fontSize='xs' fontWeight='light'>Per person</Text>
                    </div>
                    
                    <div className="flex flex-col gap-2 w-full">
                        {option.booking_urls.map((url, index) => (
                            <Button
                                key={index}
                                size="2xl"
                                onClick={() => window.open(url, '_blank')}
                                style={{ width: '100%' }}
                            >
                                Book Flight {index + 1}
                            </Button>
                        ))}
                    </div>
                </div>
            );
        }

        // Original single booking URL rendering
        return (
            <div className='flight-option-button-wrapper'>
                <div className='flight-option-price-display'>
                    <HeadText fontSize='s'>{this.getPrice()}</HeadText>
                    <Text fontSize='xs' fontWeight='light'>Per person</Text>
                </div>

                <Button
                    size="2xl"
                    disabled={!option.booking_url}
                    onClick={() => window.open(option.booking_url, '_blank')}
                    style={{ width: '100%' }}
                >Book Now</Button>
            </div>
        );
    }

    getAirlinesFromLeg = (leg: FlightLegOption): string[] => {
        if (leg.airlines) return leg.airlines;
        if (leg.airline) return [leg.airline];
        return [];
    }

    getCardTitle = () => {
        const { option } = this.props;

        if (this.isRoundTrip()) {
            const roundTripOption = option as RoundTripFlight;
            const originating  = roundTripOption.departure_option.departure_airport;
            const arriving = roundTripOption.departure_option.arrival_airport;

            return `${originating} <> ${arriving}`;

        } else if (this.isMultiCity()) {
            const multiCityOption = option as MultiCityFlight;
            const sortedLegs = this.sortLegs(multiCityOption.legs);

            let title = '';

            for (let i=0; i<sortedLegs.length; i++) {
                const leg = sortedLegs[i];

                if (i === 0) {
                    title += leg.departure_airport;
                }

                title += ` > ${leg.arrival_airport}`;
            }

            return title;

        } else {
            const oneWayOption = option as OneWayFlight;
            return `${oneWayOption.departure_airport} > ${oneWayOption.arrival_airport}`;
        }
    }

    getCardSubTitle = () => {
        const { option } = this.props;

        if (this.isRoundTrip()) {
            const roundTripOption = option as RoundTripFlight;
            const originating  = roundTripOption.departure_option.airlines;
            const arriving = roundTripOption.departure_option.airlines;
            const set = new Set([...originating, ...arriving]);
            return Array.from(set).join(' / ');
        } else if (this.isMultiCity()) {
            const multiCityOption = option as MultiCityFlight;
            const sortedLegs = this.sortLegs(multiCityOption.legs);
    
            const allAirlines = sortedLegs.flatMap(leg => this.getAirlinesFromLeg(leg));
            const uniqueAirlines = new Set(allAirlines);
            
            return Array.from(uniqueAirlines).join(' / ');
        } else {
            const oneWayOption = option as OneWayFlight;
            const airlines = oneWayOption.airlines || [];
            const set = new Set(airlines);
            return Array.from(set).join(' / ');
        }
    }

    getLayoversFromLeg = (leg: FlightLegOption): string[] => {
        if (!leg.layovers) return [];
        return leg.layovers.map(l => l.airport_code);
    }

    render() {
        const { option, index, ranking_reasoning } = this.props;
        const isTopPick = index === 0;

        return (
            <div className="adapter-intention-option">
                <div className="option-price-header">
                    <div className="option-price-header-wrapper">
                        <HeadText fontSize='xs'>{this.getCardTitle()}</HeadText>
                        <Text fontSize='s'>{this.getCardSubTitle()}</Text>
                    </div>
                    {isTopPick && <div className='absolute top-0 right-0'><Pill highlighted>Best Match</Pill></div>}
                </div>

                {this.renderFlightDetails()}

                {this.renderButton()}

                {!this.isMultiCity && (
                <>
                    <MatchComparisonBar 
                        score={this.props.score}
                        allScores={this.props.allScores}
                    />

                    {ranking_reasoning && (
                        <div className="option-details">
                            <div className="option-ranking-reason">
                                <h4 className="text-sm font-medium text-gray-700 mb-2">Details</h4>
                                <p className="text-sm text-gray-600">{ranking_reasoning}</p>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
        );
    }
}

export default FlightOption;