import Constraints from '../constraints';
import { ComprehensionResponse } from '..';

export interface ComprehensionView {
    text?: string;
    attrs?: { [key: string]: string[] };
    items?: string[];
}

export type LiteralType<T extends string> = T;
export abstract class ResponseBase {
    protected comprehensionResponse: ComprehensionResponse;

    constructor(comprehensionResponse?: ComprehensionResponse) {
        this.comprehensionResponse = comprehensionResponse;
    }

    abstract render(constraints?: Constraints): ComprehensionView;

    protected renderField(value: string, section: 'result' | 'constraints', field: string, subSection?: string): string {
        if (this.comprehensionResponse.isFieldInferred(section, field, subSection)) {
            return `<span class="inferred">${value}</span>`;
        }
        return value;
    }

    protected renderGeographicFeature(constraints?: Constraints): string {
        if (constraints?.geographic) {
            const { pivot, name, address } = constraints.geographic;
            let geographicDesc = '';

            if (address && name) {
                geographicDesc = `${address} (${name})`;
            } else if (address) {
                geographicDesc = address;
            } else if (name) {
                geographicDesc = name;
            }

            const pivotTerm = pivot.toLowerCase() === 'nearby' ? 'near' : pivot;
            const result = geographicDesc ? `${pivotTerm} ${geographicDesc}` : pivotTerm;
            return this.renderField(result, 'constraints', 'geographic');
        }
        return '';
    }

    protected renderDistanceConstraint(constraints?: Constraints): string {
        if (constraints?.distance) {
            const result = `Within ${constraints.distance.value} ${constraints.distance.unit} by ${constraints.distance.method}`;
            return this.renderField(result, 'constraints', 'distance');
        }
        return '';
    }
}