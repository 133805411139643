import React from 'react';

import { CloseIcon, LinkBrokenIcon } from 'components/common/Icon';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';

import './DisconnectAccountModal.scss';

type DisconnectAccountModalProps = {
    onCancel: () => void;
    onDisconnect: () => void;
}

const DisconnectAccountModal = ({ onCancel, onDisconnect }: DisconnectAccountModalProps) => (
    <Modal onClose={onCancel}>
        <div className="disconnect-confirm">
            <div className="disconnect-confirm-head">
                <LinkBrokenIcon color="#5C33CC" />
                <CloseIcon
                    onClick={onCancel}
                    style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }}
                />
            </div>

            <div className="disconnect-confirm-body">
                <h3>Disconnect Account?</h3>

                <p>
                    Adapter will no longer be able to retrieve data from this account. 
                    This includes both new and historical data.
                </p>

                <p>
                    However, encrypted copies of data from this account, already stored within Adapter, 
                    will not be deleted and this data may be re-analyzed by Adapter in the future to extract 
                    additional details for your use. Information already extracted from this account&apos;s 
                    data for your use within Adapter, or generated when you interacted with this 
                    account&apos;s data inside of Adapter, will also not be deleted. In order to fully delete 
                    this information you must permanently delete your Adapter account by contacting customer support.
                </p>
            </div>

            <div className="disconnect-confirm-foot">
                <Button onClick={onDisconnect}>Disconnect</Button>
            </div>
        </div>
    </Modal>
);

export default DisconnectAccountModal;